import gettext from 'airborne/gettext';
import {Field} from 'midoffice/newforms/fields-stateless';
import {createSchema, required} from 'midoffice/newforms/helpers';
import {PasswordField, RepeatPassword} from 'midoffice/newforms/validators/password';
const сhangePasswordSchema = createSchema({
    fields: {
        'old_password': required({...Field, minLength: 1}, gettext(Field.emptyMessage)),
        'password1': PasswordField,
        'password2': RepeatPassword,
    },
});


export default сhangePasswordSchema;
