import SentryAdapter from 'airborne/sentryAdapter';

async function air3DVerification(searchParams, store) {
    const verificationId = searchParams.get('verification_id');

    if (!verificationId) {
        return Promise.resolve();
    }

    // We don't want to load the whole checkout module if it's not needed at application start
    // Also preventing potential bugs when Schema files are initializing before we have user settings
    const {completeBooking} = await import('airborne/air/store/checkout/actions/book');
    await store.dispatch(completeBooking(verificationId));
}

export async function initialActions(searchParams, settings, store) {
    try {
        const isLoggedIn = Boolean(settings.USER?.id);

        if (!isLoggedIn) {
            return Promise.resolve();
        }

        await air3DVerification(searchParams, store);
    }
    catch (error) {
        if (error instanceof Error) {
            SentryAdapter.captureException(error);
        }
    }
}
