'process i18n';
import React from 'react';
import PropTypes from 'prop-types';
import Tabs from 'midoffice/components/Tabs';

import gettext from 'airborne/gettext';
import isEmpty from 'lodash/isEmpty';
import values from 'lodash/values';
import compact from 'lodash/compact';

import {getBookingIssues} from 'midoffice/bookings/records/BookingIssues';

export default class BookingInfoTabs extends React.Component {
    static propTypes = {
        booking: PropTypes.object,
        handleTabSelect: PropTypes.func,
        activeTab: PropTypes.string,
        historyTabLabel: PropTypes.string,
        isAir: PropTypes.bool,
    };

    static defaultProps = {
        historyTabLabel: gettext('Booking History'),
    };

    render() {
        const {booking, handleTabSelect, activeTab, historyTabLabel, isAir} = this.props;
        // TODO: Remove isAir prop once GG-32652 is done
        const issues = compact(values(getBookingIssues(booking, isAir)));
        const tabs = [
            ['details', gettext('Details')],
            ['history', historyTabLabel],
            ...(isEmpty(issues)
                ? []
                : [['issues', gettext('Booking Issues')]]
            ),
        ];

        return (
            <Tabs disableRowWrapper onSelect={handleTabSelect} className="tabs__nav--table">
                {tabs.map(([key, label]) => (
                    <Tabs.Tab key={key} id={key} active={activeTab === key}>
                        {label}
                    </Tabs.Tab>
                ))}
            </Tabs>
        );
    }
}
