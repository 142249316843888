import get from 'lodash/get';
import air from 'airborne/store/modules/search_hotels/actions/air';
import * as actionTypes from '../actionTypes';
import normalize from "airborne/air/shared/helpers/normalize";
import {apiError} from "airborne/air/shared/helpers/apiError";

export const requestTickets = ({bookingUid}) => async (dispatch) => {
    dispatch({ type: actionTypes.AIR_TICKETS_LOADING });

    try {
        const response = await air(
            'POST',
            '/air/documents_manage/',
            {'booking_uid': bookingUid, operation: 'list'}
        );

        const tickets = get(response, 'tickets', []);

        dispatch({ type: actionTypes.AIR_TICKETS_LOADED, data: normalize(tickets) });
    } catch (error) {
        const errorMessage = apiError(error);

        dispatch({ type: actionTypes.AIR_TICKETS_FAIL, error: errorMessage });
    } finally {
        dispatch({ type: actionTypes.AIR_TICKETS_LOAD_END });
    }
};
