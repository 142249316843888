import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import Row from 'react-bootstrap/Row';
import {connect} from 'react-redux';

import gettext from 'airborne/gettext';
import {
    carsChangeSearchType,
} from 'airborne/store/modules/homepage/actions';
import {
    getCarsSearchType,
} from 'airborne/store/modules/homepage/selectors/homepage';

import {CARS_SEARCH_TYPES} from './types';
import CarsDestinationSearch from 'airborne/cars/homepage/CarsDestinationSearch';
import DirectSellSearch from 'airborne/cars/homepage/DirectSellSearch';
import {getPnrProfile} from 'airborne/store/modules/homepage/selectors/pnrProfile';

const TabButton = ({
    type,
    active,
    onClick,
    children,
    disabled,
    testId,
}) => {
    const getClasses = (active) =>
        classnames('btn', {'ag-home-form__switcher--active': active});

    const handleClick = () => {
        onClick(type);
    };

    return (
        <button
            className={getClasses(active)}
            onClick={handleClick}
            disabled={disabled}
            data-testid={testId}
        >
            {children}
        </button>
    );
};

TabButton.propTypes = {
    children: PropTypes.node,
    type: PropTypes.string,
    active: PropTypes.bool,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    testId: PropTypes.string,
};

@connect(state => {
    return ({
        searchType: getCarsSearchType(state),
        pnr: getPnrProfile(state, 0).pnr,
    })
},
{
    changeSearchType: carsChangeSearchType,
})
export default class CarsSearch extends React.Component {
    static propTypes = {

        // From store state
        searchType: PropTypes.string,
        modifySearchFlags: PropTypes.oneOfType([
            PropTypes.shape({
                availabilityFlow: PropTypes.bool,
                fareSearchFlow: PropTypes.bool
            }),
            PropTypes.oneOf([null]),
        ]),
        editable: PropTypes.bool,
        pnr: PropTypes.string,

        // From store dispatch
        changeSearchType: PropTypes.func,
    }

    componentDidMount() {
        const {changeSearchType, searchType} = this.props;
        
        changeSearchType(searchType);
    }

    static defaultProps = {
        searchType: CARS_SEARCH_TYPES.STANDARD_SEARCH,
    };

    onTabClick = (type) => {
        const {changeSearchType} = this.props;

        changeSearchType(type);
    }

    renderTabs() {
        const {
            searchType,
        } = this.props;

        return (
            <Row>
                <div className="ag-home-form__switcher">
                    <TabButton
                        onClick={this.onTabClick}
                        type={CARS_SEARCH_TYPES.STANDARD_SEARCH}
                        active={CARS_SEARCH_TYPES.STANDARD_SEARCH === searchType}
                        testId="cars-standard-search-tab-radio"
                    >
                        {gettext('Standard Search')}
                    </TabButton>
                    <TabButton
                        onClick={this.onTabClick}
                        type={CARS_SEARCH_TYPES.DIRECT_SELL}
                        active={CARS_SEARCH_TYPES.DIRECT_SELL === searchType}
                        testId="cars-direct-sell-search-tab-radio"
                    >
                        {gettext('Direct Sell')}
                    </TabButton>
                </div>
            </Row>
        );
    }

    renderPNR(pnr) {
        pnr = pnr || gettext('Not selected');

        return <span>&nbsp;<strong>{pnr}</strong></span>;
    }

    renderEditor() {
        const {
            searchType,
        } = this.props;

        if (searchType === CARS_SEARCH_TYPES.STANDARD_SEARCH) {
            return <CarsDestinationSearch {...this.props} />;
        }

        return <DirectSellSearch {...this.props} />;
    }

    render() {
        const {pnr, editable} = this.props;

        return (
            <Fragment>
                <h4 className="block-title">
                    {gettext('Add car segment to your PNR:')}
                    {editable && this.renderPNR(pnr)}
                </h4>
                {this.renderTabs()}
                {this.renderEditor()}
            </Fragment>
        );
    }
};
