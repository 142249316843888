import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import MultiCheckbox from 'midoffice/newforms/widgets/MultiCheckbox';
import Radios from 'midoffice/newforms/widgets/Radios';

import {injectFormContext} from 'midoffice/newforms/decorators';
import {noErrors} from 'midoffice/newforms/helpers';

import WrongBedTypeSchema from './WrongBedTypeSchema';
import gettext from 'airborne/gettext';

import {connect} from 'react-redux';

import {getRate, displayRoomType, displayBedTypes} from 'airborne/search2/helpers/rates';
import {fetchCategories} from 'midoffice/store/modules/bedTypes/actions';
import {getCategories} from 'midoffice/store/modules/bedTypes/selectors';
import api from 'midoffice/helpers/api';
import settings from 'airborne/settings';
import {Trans} from 'react-i18next';

@injectFormContext
class Editor extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func,
        options: PropTypes.array,
    };

    static defaultProps = {
        options: [],
    };

    render() {
        const {onSubmit} = this.props;


        const extra = {
            control: true,
            inputCol: 7,
        };
        return (
            <form className="form-horizontal" onSubmit={onSubmit}>
                {this.props.options.map(({Component, name, label, className, choices, asDiv, table}) => (
                    <div className="row-wrapper row-wrapper--condensed" key={name}>
                        <Component.Field
                            name={name}
                            label={gettext(label)}
                            className={className}
                            choices={choices}
                            asDiv={asDiv}
                            table={table}
                            {...extra}
                        />
                    </div>
                ))}
            </form>
        );
    }
}


class WrongBedTypeModal extends React.Component {
    static propTypes = {
        value: PropTypes.object,
        loading: PropTypes.bool.isRequired,
        onSubmit: PropTypes.func,
        onHide: PropTypes.func.isRequired,
        hotelId: PropTypes.string.isRequired,
        rateKey: PropTypes.string.isRequired,
        rate: PropTypes.shape({
            roomDescription: PropTypes.string,
            rateDescription: PropTypes.string.isRequired,
            additionalRateInformation: PropTypes.string,
            bedTypes: PropTypes.arrayOf(PropTypes.string),
            roomType: PropTypes.string,
        }),
        categories:  PropTypes.array,
        fetchCategories: PropTypes.func.isRequired,
    };

    state = {
        value: {},
        errors: null,
    };

    //#TODO Will be done later
    componentDidMount() {
        this.props.fetchCategories();
    }

    getOptions() {
        const extraProps = {
            'room_type': {
                label: gettext('Suggested Room Type'),
                className: 'multi-selector--radio-select',
                asDiv: true,
            },
            'bed_types': {
                label: gettext('Suggested Bed Type'),
                table: false,
            },
        };

        return this.props.categories.map(
            ({name, options, 'many_accepted': manyAccepted}) => ({
                name,
                choices: options.map(({key, name}) => [key, name]),
                Component: manyAccepted ? MultiCheckbox : Radios,
                ...extraProps[name],
            })
        );
    }

    sendFeedback = async (value) => {
        try {
            await api('POST', '/internal/ml/labeling/feedback', {
                data: {
                    ...value,
                    description: [
                        this.props.rate.roomDescription,
                        this.props.rate.rateDescription,
                        this.props.rate.additionalRateInformation
                    ].filter(element => !!element).join('\n'),
                    'session_id': settings.sid || 'none',
                },
            });
            this.props.onHide();
        }
        catch (err) {
            this.setState({error: err});
        }
    }

    handleChange = ({value, errors})=> {
        this.setState({value, errors});
    };

    handleSubmit = ()=> {
        const {value, errors} = WrongBedTypeSchema.toRepresentation(this.state.value);
        if (noErrors(errors)) {
            this.sendFeedback(value);
        }
        else {
            this.setState({value, errors});
        }
    };

    render() {
        const {value, errors} = this.state;
        const {onHide, rate: {rateDescription, roomDescription, additionalRateInformation, bedTypes, roomType}} = this.props;

        return (
            <Modal show onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>{gettext('Report wrong room/bed type')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row-wrapper">
                        <p className="alert alert-warning">
                            {gettext('The room and bed type displayed is generated by automatic algorithms, this could occasionally be incorrect.')}
                        </p>
                        <p className="alert alert-danger">
                            <Trans i18nKey="wrongBedTypeModalAlert" components={{b: <strong/>}} />
                            <br />
                            {gettext('If the description does not include the correct type, please don’t select anything.')}
                        </p>
                    </div>
                    <table className="table">
                        <tbody>
                            <tr>
                                <th style={{width: '32%'}}>{gettext('Room Description')}</th>
                                <td>{roomDescription ? <div dangerouslySetInnerHTML={{__html: roomDescription}} /> : 'Unknown'}</td>
                            </tr>
                            <tr>
                                <th style={{width: '32%'}}>{gettext('Rate Description')}</th>
                                <td>{rateDescription}</td>
                            </tr>
                            <tr>
                                <th style={{width: '32%'}}>{gettext('Additional Rate Information')}</th>
                                <td>{additionalRateInformation ? additionalRateInformation : 'Unknown'}</td>
                            </tr>
                            <tr>
                                <th style={{width: '32%'}}>{gettext('Detected Room Type')}</th>
                                <td>{displayRoomType(roomType)}</td>
                            </tr>
                            <tr>
                                <th style={{width: '32%'}}>{gettext('Detected Bed Type')}</th>

                                <td>{displayBedTypes(bedTypes)}</td>
                            </tr>
                        </tbody>
                    </table>

                    <hr />

                    <Editor
                        schema={WrongBedTypeSchema}
                        options={this.getOptions()}
                        value={value}
                        errors={errors}
                        onChange={this.handleChange}
                        onSubmit={this.handleSubmit} />

                </Modal.Body>
                <Modal.Footer>
                    <Button name="cancel" variant="light" onClick={onHide}>{gettext('Close')}</Button>
                    <Button name="submit" variant="success" onClick={this.handleSubmit}>{gettext('Submit')}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}


function mapStateProps(state, {hotelId, rateKey}) {
    const categories = getCategories(state);

    return {
        rate: getRate(state, hotelId, rateKey, true),
        categories: categories || [],
        loading: !Boolean(categories),
    };
}

export default connect(mapStateProps, {fetchCategories})(WrongBedTypeModal);
