import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Glyphicons from 'midoffice/components/Glyphicons';
import Alert from 'react-bootstrap/Alert';

import gettext from 'airborne/gettext';
import browserHistory from 'airborne/browserHistory';
import {Trans} from 'react-i18next';


export default class NoAcceptedCardsModal extends React.Component {
    static propTypes = {
        onHide: PropTypes.func.isRequired,
    };

    onBack = ()=> {
        browserHistory.goBack();
    }

    render() {
        const {onHide} = this.props;

        return (<Modal show>
            <div className="info-modal">
                <Modal.Body>
                    <Alert color="danger" closeLabel="Close alert" className="alert-danger">
                        <Glyphicons bsClass="glyphicon" glyph="exclamation-sign" />
                        <Trans
                            i18nKey="notAcceptedCardModalAlert"
                            components={{b: <strong/>}}
                        />
                    </Alert>
                    <hr />
                    <p>
                        <Trans
                            i18nKey="notAcceptedCardModalText"
                            components={{b: <strong/>}}
                        />
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.onBack} variant="link" className="btn-link--narrow pull-left">
                        <strong>{gettext('« Select a different rate')}</strong>
                    </Button>
                    <Button onClick={onHide} color="primary">{gettext('Proceed')}</Button>
                </Modal.Footer>
            </div>
        </Modal>);
    }
}
