import  * as types from '../actionTypes';
import air from 'airborne/store/modules/search_hotels/actions/air';
import {
    getFareGroupKey,
    getFlightOptionKeys,
    isSeatMapLoadedByKeys,
    getSeatMapRequests,
} from 'airborne/air/store/fare_search/selectors';
import {getPnrProfile} from 'airborne/store/modules/homepage/selectors/pnrProfile';
import {parse} from 'airborne/types';
import {
    getUniqueFareKey,
    serializeFTNumbers,
} from 'airborne/air/fare_search/helpers';
import {getErrorMessages} from 'airborne/helpers/apiError';
import {getCheckoutFrequentFlyerNumbers} from 'airborne/air/store/checkout/selectors';
import {dropEmptyDeep} from 'airborne/helpers/emptyDeep';
import {getSelectedConfiguration} from 'airborne/store/modules/homepage/selectors/tspm';

export const loadingSeatMap = () => ({
    type: types.AIR_SEAT_MAP_LOADING
});

export const failSeatMap = errors => ({
    type: types.AIR_SEAT_MAP_FAIL,
    errors
});

export const setSeatMap = data => ({
    type: types.AIR_SEAT_MAP_LOADED,
    data,
});

const addSeatMapRequest = requestId => ({type: types.AIR_SEAT_MAP_ADD_REQUEST, requestId});
export const clearSeatMapRequests = () => ({type: types.AIR_SEAT_MAP_CLEAR_REQUESTS});

const getSeatMap = (fareGroupKey, flightOptionKeys) => async (dispatch, getState) => {
    const storeKey = getUniqueFareKey({fareGroupKey, flightOptionKeys});
    const company = getPnrProfile(getState(), 0)?.company;
    const frequentFlyerNumbers = getCheckoutFrequentFlyerNumbers(getState());

    dispatch(loadingSeatMap());
    try {
        const {seat_maps: seatMaps} = await air('POST', '/air/seat_map/', dropEmptyDeep({
            'configuration_id': getSelectedConfiguration(getState()) || company,
            'fare_group_key': fareGroupKey,
            'flight_option_keys': flightOptionKeys,
            'traveler': {
                'ff_numbers': serializeFTNumbers(frequentFlyerNumbers)
            }
        }));

        const parsedSeatMaps = seatMaps.map(seatMap => parse('seatMap', seatMap));

        dispatch(setSeatMap({
            // We store seat map on the unique key that IS a fareGroup key + flightOptions keys combination
            [storeKey]: parsedSeatMaps
        }));
    }
    catch (errorResponse) {
        const errorMessages = getErrorMessages(errorResponse);
        dispatch(failSeatMap(errorMessages));
    }
};

export const requestSeatMap = (ticketIndex = 0) => async (dispatch, getState) => {
    const state = getState();
    const fareGroupKey = getFareGroupKey(state, ticketIndex);
    const flightOptionKeys = getFlightOptionKeys(state, ticketIndex);

    await dispatch(getSeatMap(fareGroupKey, flightOptionKeys));
};

export const requestSingleSeatMap = (fareGroupKey, flightOptionKeys) => async (dispatch, getState) => {
    const key = getUniqueFareKey({fareGroupKey, flightOptionKeys});
    const isAlreadyLoaded = isSeatMapLoadedByKeys(getState(), fareGroupKey, flightOptionKeys);
    const requests = getSeatMapRequests(getState());

    if (isAlreadyLoaded || requests.includes(key)) {
        return Promise.resolve();
    }

    // We are making one request only, and even if it fails we don't make another one,
    // because there is a lot of 404 responses.
    // We are clearing them when we're going back to search results tho
    dispatch(addSeatMapRequest(key));
    await dispatch(getSeatMap(fareGroupKey, flightOptionKeys));
};
