import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import RoutesModification from './Modification';
import gettext from 'airborne/gettext';

import {isAirExchangeActive} from 'airborne/air/store/exchange/selectors';
import {cancelAirExchange} from 'airborne/air/store/exchange/actions';
import {isBookingConfirmed} from 'airborne/air/store/checkout/selectors';

@connect(state => ({
    isActive: isAirExchangeActive(state),
    isConfirmed: isBookingConfirmed(state),
}), {
    cancelAirExchange,
})
export default class ExchangeAir extends React.Component {

    modalRef = React.createRef(null);

    static propTypes = {
        isActive: PropTypes.bool,
        cancelAirExchange: PropTypes.func,
        isConfirmed: PropTypes.bool,
    }

    handleClose = () => {
        const {cancelAirExchange} = this.props;

        cancelAirExchange();
    }

    render () {
        const {isActive, isConfirmed} = this.props;

        return (
            isActive && (
                <>
                    <Modal show className="modal--full-page" ref={this.modalRef}>
                        <Modal.Header className="modal-header--transparent">
                            <Modal.Title>
                                {gettext('Routes Modification')}
                                <button onClick={this.handleClose} className="close">
                                    {gettext('{action} the Modification', {action: isConfirmed ? 'Close' : 'Cancel'})}{' '}
                                </button>
                            </Modal.Title>
                        </Modal.Header>
                        <RoutesModification modalRef={this.modalRef} />
                    </Modal>
                </>
            )
        );
    }
}
