'process i18n';
import React from 'react';
import PropTypes from 'prop-types';

import gettext from 'airborne/gettext';
import ModalAlert from 'midoffice/bookings/ModalAlert';
import {pnrFormat} from 'midoffice/helpers/format';

export default class PartialWarning extends React.Component {

    static propTypes = {
        status: PropTypes.string.isRequired,
        gds: PropTypes.string.isRequired,
        number: PropTypes.string.isRequired,
    };

    render() {
        const {status, gds, number} = this.props;
        if (status !== 'partial') {
            return null;
        }
        let message = (<>
            {gettext('You are about to cancel a confirmed hotel aggregator booking that is missing a corresponding passive segment in the GDS PNR. Canceling this booking is allowed with the hotel aggregator, but no information will be written to PNR')}
            <strong>{pnrFormat(number, gds)}</strong>
        </>);

        return (<ModalAlert type="warning"
            title={gettext('Missing GDS Passive Segment')}
            message={message} />);
    }
}

