'process i18n';
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import gettext from 'airborne/gettext';
import toPairs from 'lodash/toPairs';
import get from 'lodash/get';
import camelCase from 'lodash/camelCase';

const getBookingProperty = (booking, property, isAir) => get(booking, isAir ? camelCase(property) : property);

// TODO: Remove isAir option once GG-32652 is done
export const getBookingIssues = (booking, isAir = false) => {
    const bookingRemarksError = getBookingProperty(booking, 'booking_remarks_error', isAir);
    const postTicketingRemarksError = getBookingProperty(booking, 'post_ticketing_remarks_error', isAir);
    const cancellationRemarksError = getBookingProperty(booking, 'cancellation_remarks_error', isAir);
    const remarksAddResponse = getBookingProperty(booking, 'remarks_add_response', isAir);
    const remarksCancelResponse = getBookingProperty(booking, 'remarks_cancel_response', isAir);
    const passiveSegmentsError = getBookingProperty(booking, 'passive_segments_error', isAir);

    const addRemarksError = bookingRemarksError || remarksAddResponse;
    const cancelRemarksError = cancellationRemarksError || remarksCancelResponse;

    return {addRemarksError, cancelRemarksError, passiveSegmentsError, postTicketingRemarksError};
};

export default class BookingIssues extends React.Component {
    static propTypes = {
        booking: PropTypes.object,
        isAir: PropTypes.bool,
    };

    renderIssues(issues) {
        const labels = {
            addRemarksError: gettext('Add remarks error:'),
            postTicketingRemarksError: gettext('Post ticketing remarks error:'),
            cancelRemarksError: gettext('Cancellation Issue:'),
            passiveSegmentsError: gettext('Passive segments error:'),
        };

        return toPairs(issues)
            .filter(([, value]) => Boolean(value))
            .map(([key, value], index, array) => {
                return (
                    <Fragment key={key}>
                        <strong className="highlight-red">{labels[key]}</strong>
                        <br />
                        &nbsp;&nbsp;{value}
                        {array[index + 1] && (
                            <>
                                <br />
                                <br />
                            </>
                        )}
                    </Fragment>
                );
            });
    }

    render() {
        // TODO: Remove isAir option once GG-32652 is done
        const {isAir} = this.props;
        const issues = getBookingIssues(this.props.booking, isAir);

        return (
            <div className="tabs__nav__content">
                <pre className="well well-sm">
                    {this.renderIssues(issues)}
                </pre>
            </div>
        );
    }
}
