import React from 'react';
import PropTypes from 'prop-types';

const renderDetailsItem = (label, info) => (
    Boolean(info)
        ? (<li key={label}><strong>{label}:</strong> {info}</li>)
        : null
);

const dataToList = ({data, specialFormat, title}) => {
    const formattedDetails = Boolean(specialFormat)
        ? specialFormat
        : data.map(([label, info]) => renderDetailsItem(label, info));

    return <td key={title}><ul className="list-unstyled">{formattedDetails}</ul></td>;
};

const BookingDatatable = ({columns}) => {
    const titles = columns.map(({title}) => (
        <th key={title}>{title}</th>
    ));

    return (
        <table className="table">
            <tbody>
                <tr>
                    {titles}
                </tr>
                <tr>
                    {columns.map(dataToList)}
                </tr>
            </tbody>
        </table>
    );
};

/* eslint-disable immutable/no-mutation */
dataToList.propTypes = {
    data: PropTypes.array,
    specialFormat: PropTypes.node,
    title: PropTypes.string,
};

BookingDatatable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        data: PropTypes.array,
        specialFormat: PropTypes.node,
    })).isRequired,
};

export default BookingDatatable;
