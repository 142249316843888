// Copied from search2/helpers/apiError.js
import settings from 'airborne/settings';
import gettext from 'airborne/gettext';
import get from 'lodash/get';
import {RETRY} from './retry';

export function apiError(response) {
    if (response === RETRY) {
        return 'ETIMEOUT';
    }
    const status = get(response, 'status');
    if (status === 0) {
        return 'EOFFLINE';
    }
    const errors = get(response, 'body.errors');

    if (!Array.isArray(errors)) {
        return get(response, 'body.message') || null;
    }

    const messages = errors.map((error)=> error.message)
        .filter((item)=> item);
    return messages.length === 0 ? null : messages;
}

export function getErrorCode(response) {
    const [error] = get(response, 'body.errors') || [];
    return error ? error.error_code : null;
}


export function isErrorCodeRecoverable(errorCode) {
    if (!errorCode) {
        return false;
    }
    return !settings.AIR_BOOK_NON_RECOVERABLE_ERROR_CODES.includes(errorCode);
}

export function isErrorRecoverable(response) {
    const errorCode = getErrorCode(response);
    return errorCode
        ? isErrorCodeRecoverable(errorCode)
        : Boolean(get(response, 'body.recoverable'));
}

export function genericError() {
    return gettext('We\'re sorry, our system has encountered an error.<br>Please try again in a few minutes.').split('<br>');
}

function allExcludedError() {
    return gettext('It appears that all found hotels are hidden due to company\'s travel policy settings or hotel inclusion/exclusion rules. Please modify your search criteria. If you believe you received this message in error, please report it to customer support.');
}

function offlineError() {
    return gettext('Network error: Can’t connect to servers. Please verify your Internet connection.');
}

function aftTimeout() {
    return gettext('Something went wrong. There is a chance that the transaction went through behind the scene. Please try again in few minutes, but only after making sure that the first reservation is not present in the system.');
}

export function userMessages(message, kind=null) {
    if (message === 'EOFFLINE') {
        return [offlineError()];
    }
    if (message === 'ETIMEOUT') {
        return [aftTimeout()];
    }
    if (!message || message === 'EUNKNOWN') {
        return kind === 'excluded' ? [allExcludedError()] : genericError();
    }
    return Array.isArray(message) ? message : [message];
}
