import React from 'react';
import PropTypes from 'prop-types';


export default class User extends React.Component {
    static propTypes = {
        email: PropTypes.string,
        splitted: PropTypes.bool,
    }

    render() {
        const {email, splitted} = this.props;

        if (splitted) {
            return (<span>
                {email.replace('@', '\u200B@')}
            </span>);
        }
        return (<span>
            {email}
        </span>);
    }
}
