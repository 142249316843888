import {getErrorCodes} from 'airborne/helpers/apiError';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import {getAirTripTypeTuples} from "airborne/air/homepage/helpers/common";
import {AIR_TRIP_TYPES} from "airborne/air/homepage/types";

export function isManualRefundError(response) {
    const errorCodes = getErrorCodes(response);

    return errorCodes?.includes(1063000);
}

export function isVoidedBooking(booking) {
    const {tickets} = booking;

    if (!tickets) {
        return true;
    }

    return tickets.every(ticket => ticket.status === 'VOIDED');
}

export function isRoundTrip(destinations) {
    if (destinations.length !== 2) {
        return false;
    }

    const inboundSegment = destinations[0].segments[0];
    const outboundSegment = last(last(destinations).segments);

    return inboundSegment.departure.iataCode === outboundSegment.arrival.iataCode
        && inboundSegment.arrival.iataCode === outboundSegment.departure.iataCode;
}

export function getTripType(destinations) {
    if (destinations.length === 1) {
        return AIR_TRIP_TYPES.ONE_WAY;
    }

    if (isRoundTrip(destinations)) {
        return AIR_TRIP_TYPES.ROUND_TRIP;
    }

    return AIR_TRIP_TYPES.MULTI_CITY;
}

export function extractAirports(originDestinations) {
    return originDestinations.flatMap(
        ({segments}) => segments.flatMap(
            ({departure, arrival}) => ([departure.airport, arrival.airport])
        )
    );
}

export function extractFlightsChain(airports) {
    return reduce(
        airports,
        (flights, airport) => {
            if (isEmpty(flights) || last(flights).code !== airport.code) {
                return [...flights, airport];
            }
            return flights;
        },
        []
    );
}

export function extractTripTypeLabel(tripTypeKey) {
    const [, tripTypeLabel] = getAirTripTypeTuples().find(([tripType]) => tripType === tripTypeKey);
    return tripTypeLabel;
}
