import React from 'react';
import settings from 'airborne/settings';
import {getCurrencies} from 'airborne/constants';
import AnnotateTooltip from 'airborne/search2/hotel/AnnotateTooltip';


function getRegExp() {
    return new RegExp(`(${(getCurrencies().filter(currency => currency[0] !== '...')).map((item)=>item[0]).join('|')})`, 'g');
}

function getCurrencyAnnotation(currency) {
    const config = getCurrencies().find((item)=> item[0] === currency);
    return config ? config[1] : null;
}

function hasCurrency(text) {
    return Boolean(text.match(getRegExp()));
}

function replaceCurrancies(text) {
    const regExp = getRegExp();
    return text.split(regExp).map((item)=> {
        return item.match(regExp)
            ? (
                <AnnotateTooltip id={`cur-${item}`} key={`cur-${item}`}>
                    <abbr>{item}</abbr>
                    {getCurrencyAnnotation(item)}
                </AnnotateTooltip>
            )
            : item;
    });
}


export default function annotate(text) {
    if (!hasCurrency(text)) {
        return text;
    }

    return replaceCurrancies(text);
}
