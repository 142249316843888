import gettext from 'airborne/gettext';
import markup from 'airborne/markup';
import identity from 'lodash/identity';
import {formatCarAddress} from 'airborne/cars/checkout/helpers/destination';
import compact from 'lodash/compact';


export function formatDistance({units, value}) {
    return units === 'KM'
        ? gettext('{value} km from search location', value, {value})
        : gettext('{count} miles from search location', {count: value});
}


export function formatMileage({units, value, process=markup}) {
    return units === 'KM'
        ? process(gettext('**{value} free km** included', '**{value} free km** included', value, {value}))
        : process(gettext('**{value} free mile** included', '**{value} free miles** included', value, {value}));
}

export function formatIncludedDistance(distanceIncluded) {
    if (!distanceIncluded || distanceIncluded.distanceAmount === 0) {
        return '---';
    }
    if (distanceIncluded.unlimited) {
        return gettext('Unlimited');
    }
    return `${distanceIncluded.distanceAmount} ${distanceIncluded.distanceUnit}`;
}


export function getMiliageText(distanceIncluded) {
    const {distanceAmount, distanceUnit, unlimited=false} = distanceIncluded;

    if (unlimited) {
        return gettext('Unlimited mileage');
    }
    else if (distanceAmount) {
        return formatMileage({units: distanceUnit, value: distanceAmount, process: identity});
    }
    else {
        return distanceUnit === 'KM'
            ? gettext('No free kilometers included')
            : gettext('No free miles included');
    }
}

export function getCarLocationText(location) {
    const {airport, address} = location;
    return compact([
        formatCarAddress(address),
        airport && airport.shuttle
    ]).join('\n');
}
