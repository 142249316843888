import findIndex from 'lodash/findIndex';
import find from 'lodash/find';

import {getOptions, getDestFormValue} from 'airborne/store/modules/homepage/selectors/homepage';
import {getFeatureFlag} from 'airborne/store/modules/featureFlags/selector';
import {mergeIdNumbers, prepopulateNegotiatedNumbers} from 'airborne/store/modules/homepage/reducers/destination.js';
import {getNegotiatedChoices, getNegotiatedIdChoices, getNegotiatedIdCdMatrix} from './carVendor';
import {dropEmpty} from './homepage';

export const getCdIdMatchedValue = (vendor, number, idNumbers, cdNumbers, idCdMatrix, isCdUpdate = false) => {
    const searchKey = isCdUpdate ? 'idNumber' : 'cdNumber';

    const cdIdx = findIndex(cdNumbers, {vendor});
    const idIdx = findIndex(idNumbers, {vendor});
    const matrixEntity = find(idCdMatrix, {[searchKey]: number});

    if (matrixEntity) {
        cdNumbers[cdIdx] = {...cdNumbers[cdIdx], number: isCdUpdate ? matrixEntity.cdNumber : number};
        idNumbers[idIdx] = {...idNumbers[idIdx], idNumber: isCdUpdate ? number : matrixEntity.idNumber};

        return {
            cdNumbers,
            idNumbers,
        };
    }

    return null;
};

export const getMatchedIdNumbers = (mergedIdNumbers, cdNumbers, idCdMatrix) => {
    for (const {vendor, number} of cdNumbers) {
        const matchedIdNumbers = getCdIdMatchedValue(vendor, number, mergedIdNumbers, cdNumbers, idCdMatrix)?.idNumbers;
        mergedIdNumbers =  matchedIdNumbers || mergedIdNumbers;
    }

    return mergedIdNumbers.map(entity => entity.customIdInput ? {...entity, idNumber: 'custom'} : entity);
};

export const getVendorNegotiatedNumber = (numbers, vendor) => {
    if (!vendor) {
        return numbers;
    }

    let vendNumbers = numbers.filter(({vendor: v}) => v === vendor)
    return vendNumbers.length ? vendNumbers : [{vendor}];
}

export const getVendorNegotiatedNumbers = (cdNumbers, idNumbers, itNumbers, vendor) => {
    return {
        cdNumbers: getVendorNegotiatedNumber(cdNumbers, vendor),
        idNumbers: getVendorNegotiatedNumber(idNumbers, vendor),
        itNumbers: getVendorNegotiatedNumber(itNumbers, vendor),
    };
}
