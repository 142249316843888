import ManageTicketsModal from 'midoffice/air-bookings/ManageTicketsModal/ManageTicketsModal';
import {connect} from 'react-redux';
import {getAirBooking} from 'midoffice/air-bookings/selectors';
import {
    requestTickets,
    requestTicketsRefund,
    requestTicketsVoiding,
} from 'airborne/air/store/documents_manage/actions';
import {
    getBookingTickets,
    isTicketListLoading,
    isRefundRequestLoading,
    isVoidRequestLoading,
    getTicketListErrors,
} from 'airborne/air/store/documents_manage/selectors';
import {showError, showInfo} from 'midoffice/data/actions/modals';

const Modal = connect((state, {uid}) => {
    const booking = getAirBooking(state, uid);

    return {
        booking,
        loading: isTicketListLoading(state),
        tickets: getBookingTickets(state),
        voidRequestLoading: isVoidRequestLoading(state),
        refundRequestLoading: isRefundRequestLoading(state),
        errorMessage: getTicketListErrors(state),
    };
}, (dispatch, {uid: bookingUid}) => ({
    preload: () => dispatch(requestTickets({bookingUid})),
    requestTicketsRefund: (tickets) => dispatch(requestTicketsRefund({bookingUid, tickets})),
    requestTicketsVoiding: (tickets) => dispatch(requestTicketsVoiding({bookingUid, tickets})),
    showInfo: (message, type) => dispatch(showInfo(message, type)),
    showError: (errors) => dispatch(showError(errors)),
}))(ManageTicketsModal);

export default Modal;
