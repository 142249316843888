'process i18n';
import React from 'react';
import PT from 'prop-types';
import gettext from 'airborne/gettext';
import compact from 'lodash/compact';
import noop from 'lodash/noop';
import flatMap from 'lodash/flatMap';
import uniqBy from 'lodash/uniqBy';
import last from 'lodash/last';

import Button from 'midoffice/components/Button';
import Modal from 'react-bootstrap/Modal';
import Loader from 'airborne/search2/Loader';

import {formatStrict} from 'airborne/utils/price';
import format, {date} from 'midoffice/helpers/format';
import annotateCurrency from 'airborne/search2/helpers/annotateCurrency';
import {showDuration} from 'airborne/air/fare_search/helpers';
import {getFareRulesTabs} from 'airborne/air/fare_search/helpers/fareRules';
import {getSegmentDatetime, getRouteKey} from 'airborne/air/fare_search/helpers/destinations';
import {bookingShape} from 'midoffice/air-bookings/shapes';

import ButtonWithModal from 'midoffice/components/ButtonWithModal';
import {ControlledFareRulesModal} from 'airborne/air/checkout/FareRulesModal';

import {AIR_TRIP_TYPES} from "airborne/air/homepage/types";

import {
    extractAirports,
    extractFlightsChain,
    extractTripTypeLabel,
    getTripType,
} from "midoffice/air-bookings/modify/helpers";

class FareRulesButton extends React.Component {
    static propTypes = {
        booking: PT.object.isRequired,
    };

    render() {
        const booking = this.props.booking;
        const fareRulesTabs = getFareRulesTabs(booking);

        if (!fareRulesTabs.length) {
            return '-';
        }

        const {fareGroup: {originDestinations}} = booking;
        const ODTabs = originDestinations.map((dest) => {
            const {segments} = dest;
            const [{departure}, {arrival}] = [segments[0], segments[segments.length - 1]];
            return `${departure.airport.code} → ${arrival.airport.code}`;
        });

        return (
            <ButtonWithModal
                bsStyle="link"
                className={'btn-link--narrow no-print'}
                modal={
                    <ControlledFareRulesModal
                        ODTabs={ODTabs}
                        tabs={fareRulesTabs}
                        onHide={noop}
                    />
                }
            >
                {gettext('View Fare Rules »')}
            </ButtonWithModal>
        );
    }
}

export default class ModifyModal extends React.Component {
    static propTypes = {
        booking: bookingShape,
        onHide: PT.func.isRequired,
        loading: PT.bool,
        preload: PT.func.isRequired,
        openAirExchange: PT.func,
        airExchangeLoading: PT.bool,
        airExchangeActive: PT.bool,
        errorMessage: PT.string,
        isExternalBooking: PT.bool,
    };

    componentDidMount() {
        this.props.preload();
    }

    openAirExchange = () => {
        const {openAirExchange, booking} = this.props;

        openAirExchange(booking);
    }

    getEmdsInfo() {
        const {booking: {emds}} = this.props;
        const numbers = emds?.map(emd => emd.number).join(', ');
        const services = flatMap(emds, emd =>
            uniqBy(emd.serviceReferences?.map(service => ({...service, docType: emd.docType})), 'description')
        ).filter(Boolean);

        return [
            [gettext('EMD Number(s):'), numbers || gettext('N/A')],
            [gettext('Services:'), services.length
                ? services.map(service =>
                    `${service.docType} - ${service.description}`
                ).join('; ')
                : gettext('N/A')
            ]
        ];

    }

    renderAirport(airport) {
        const {code, name} = airport;
        return (<><strong>{code}</strong>{name ? ` - ${airport.name}` : ''}</>);
    }

    renderAirports(segments) {
        const departureAirport = segments[0].departure.airport;
        const arrivalAirport = segments[segments.length - 1].arrival.airport;

        return (
            <>
                {this.renderAirport(departureAirport)}
                &nbsp;→&nbsp;
                {this.renderAirport(arrivalAirport)}
            </>
        );
    }

    renderMultiCityAirports() {
        const {booking} = this.props;
        const originDestinations = booking.fareGroup.originDestinations;

        const airports = extractAirports(originDestinations);

        const flightsChain = extractFlightsChain(airports);

        return flightsChain.map((airport, index) => (
            <>
                {this.renderAirport(airport)}
                {airport !== last(flightsChain) && <>&nbsp;→&nbsp;</>}
            </>
        ));
    }

    renderLocations() {
        const {booking} = this.props;
        const originDestinations = booking.fareGroup.originDestinations;

        const tripType = getTripType(originDestinations);

        if ([AIR_TRIP_TYPES.ONE_WAY, AIR_TRIP_TYPES.ROUND_TRIP].includes(tripType)) {
            const {segments} = originDestinations[0];
            return this.renderAirports(segments);
        }

        return this.renderMultiCityAirports();
    }

    renderTicketNumbers({tickets}) {
        return tickets && tickets.map(({number, status}) => `${number} (${status})`);
    }

    renderTripType() {
        const {booking} = this.props;
        const destTripType = getTripType(booking.fareGroup.originDestinations);
        return extractTripTypeLabel(destTripType);
    }

    renderBookingDetails() {
        const {booking} = this.props;
        const {
            fareGroup: {
                originDestinations,
            },
            gdsProfile: {
                officeId: gdsOID,
            },
            createdAt,
            pnr,
            confirmation: {
                airlineLocator,
                farelogixLocator,
                total,
                currency,
            },
            farelogixPcc,
            travelers,
            tickets,
        } = booking;
        const pnrId = pnr?.id;

        const NA = gettext('N/A');
        const getTravelerName = ({
            title,
            firstName,
            lastName
        }) => compact([title, firstName, lastName]).join(' ');

        const fareLogixFields = [];
        if (farelogixLocator) {
            fareLogixFields.push([gettext('FLX locator:'), farelogixLocator]);
        }
        if (farelogixPcc) {
            fareLogixFields.push([gettext('FLX PCC:'), farelogixPcc]);
        }
        const bookingInfo = [
            [gettext('Fare Rules'), <FareRulesButton booking={booking} />],
            [gettext('Traveler Name(s):'), travelers?.map(getTravelerName).join(', ') || NA],
            ...fareLogixFields,
            [gettext('Ticket Number(s):'), this.renderTicketNumbers({tickets}) || NA],
            ...this.getEmdsInfo(),
            [gettext('Date Booked:'), format.date(createdAt, 'locale')],
            [gettext('PCC/OID:'), gdsOID],
            [gettext('PNR:'), pnrId || gettext('N/A')],
            [gettext('Airline locator:'), airlineLocator || '-'],
            [gettext('Total Trip Amount:'), currency ? annotateCurrency(formatStrict(total, currency)) : total],
        ];

        return (
            <div className="rev-details__section">
                <div className="rev-details__content">
                    <h3 className="rev-details__content__title">
                        {gettext('Booking details')}
                    </h3>

                    <table className="table table-condensed">
                        <tbody>
                            <tr>
                                <td className="rev-details__content__label">
                                    {gettext('Destination:')}
                                </td>
                                <td>
                                    <div>
                                        {this.renderLocations()}
                                    </div>
                                    <hr className="hr-condensed hr--dotted" />
                                    <div className="text-gray">{this.renderTripType()}</div>
                                </td>
                            </tr>

                            {bookingInfo.map(([label, info]) => (
                                <tr key={label}>
                                    <td className="rev-details__content__label">
                                        {label}
                                    </td>
                                    <td>
                                        {info}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    renderRoute = (flightDestination) => {
        const {segments, duration} = flightDestination;
        const departure = segments[0].departure;
        const arrival = segments[segments.length - 1].arrival;


        const {airport: departureAirport} = departure;
        const {airport: arrivalAirport} = arrival;

        return (
            <table className="table table-condensed" key={getRouteKey(flightDestination)}>
                <tbody>
                    <tr>
                        <td colSpan="100">
                            <strong>{departureAirport.code} → {arrivalAirport.code}:</strong>
                            {duration && <span className="pull-right">{gettext('Trip Duration:')} {showDuration(duration)}</span>}
                        </td>
                    </tr>

                    <tr>
                        <td className="rev-details__content__label">
                            {date(getSegmentDatetime(departure), 'localeLong')}
                        </td>
                        <td>
                            <div>
                                {this.renderAirport(departureAirport)}
                            </div>

                            <div className="text-gray">
                                {departureAirport.location}
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td className="rev-details__content__label">
                            {date(getSegmentDatetime(arrival), 'localeLong')}
                        </td>
                        <td>
                            <div>
                                {this.renderAirport(arrivalAirport)}
                            </div>
                            <div className="text-gray">
                                {arrivalAirport.location}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }

    renderRoutes() {
        const {
            airExchangeLoading,
            airExchangeActive,
            booking
        } = this.props;

        const {
            fareGroup: {originDestinations},
            modifyFlights,
        } = booking;
        return (
            <div className="rev-details__section">
                <div className="rev-details__content">
                    <h3 className="rev-details__content__title">
                        {gettext('Route(s)')}
                    </h3>

                    {originDestinations.map(this.renderRoute)}
                </div>
                <div className="rev-details__control">
                    <Button
                        disabled={airExchangeActive || !modifyFlights}
                        progress={airExchangeLoading}
                        onClick={this.openAirExchange}
                        bsStyle="secondary"
                    >{gettext('Modify')}</Button>

                    <div className="rev-details__control__message">
                        * {gettext('Routes modification will cause the creation of the new booking and the cancellation of the current one.')}
                    </div>
                </div>
            </div>
        );
    }

    renderWarnings() {
        const {booking: {modificationWarnings}} = this.props;
        if (!modificationWarnings?.length) {
            return null;
        }

        return (
            <div className="alert alert-warning">
                {modificationWarnings.map(({message}, idx) => <div key={idx}>{message}</div>)}
            </div>
        );
    }

    renderErrors(errors) {
        return (
            <div className="alert alert-danger">
                {errors}
            </div>
        );
    }

    renderBody() {
        const {booking} = this.props;
        if (!booking) {
            return null;
        }

        return (
            <div className="form-horizontal">
                {this.renderWarnings()}
                {this.renderBookingDetails()}
                {this.renderRoutes()}
            </div>
        );
    }

    render() {
        const {onHide, loading, errorMessage} = this.props;

        return (
            <Modal show onHide={onHide} className="modal--full-page">
                <Modal.Header closeButton>
                    <h4 className="modal-title">{gettext('Booking Modification')}</h4>
                </Modal.Header>
                <Modal.Body>
                    {loading
                        ? <Loader light wrapped/>
                        : errorMessage
                            ? this.renderErrors(errorMessage)
                            : this.renderBody()
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button  bsStyle="light" onClick={onHide}>
                        {gettext('Close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
