'process i18n';
import gettext from 'airborne/gettext';

export const STATUS_LABELS = {
    'active': gettext('Active'),
    'canceled': gettext('Canceled'),
    'past': gettext('Past'),
    'pending': gettext('Pending'),
    'failed_to_confirm': gettext('Failed to Confirm')
};

export const PROVIDER_NAMES = {
    'ba': 'British Airways'
};

const BOOKING_ORIGINS = {
    production: 'PRODUCTION',
    bcdSandbox: 'BCD_SANDBOX',
    tfSandbox: 'TF_SANDBOX',
    providerSandbox: 'PROVIDER_SANDBOX',
};

export const isTestBookingOrigin = (bookingOrigin) => {
    return Object.values(BOOKING_ORIGINS)
        .filter(origin => origin !== BOOKING_ORIGINS.production)
        .includes(bookingOrigin);
};
