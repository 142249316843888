import settings from 'airborne/settings';
import sortBy from 'lodash/sortBy';
import memoize from 'lodash/memoize';

import {getCountries} from 'airborne/constants';

export const getCountryNameByCountryCode = memoize(
    () => getCountries().reduce(
        (acc, [countryCode, countryName]) => ({...acc, [countryCode]: countryName}), {}
    )
);

export const getPhoneCodeChoices = memoize(
    () => sortBy(
        Object.entries(settings.PHONE_REGION_CODES).reduce(
            (acc, [countryCode, callingCode]) => {
                return getCountryNameByCountryCode()[countryCode]
                    ? [
                        ...acc,
                        [
                            countryCode,
                            `${getCountryNameByCountryCode()[countryCode]} (+${callingCode})`
                        ]
                    ]
                    : acc;
            },
            []
        ),
        (([, label]) => label)
    )
);

export const getPhoneCodeByCountryCode = memoize(
    () => Object.entries(settings.PHONE_REGION_CODES).reduce(
        (acc, [countryCode, callingCode]) => (
            {...acc, [countryCode]: `+${callingCode}`}),
        {}
    )
);
