import * as types from '../actionTypes';
import generateUUID from 'airborne/checkout2/helpers/uuid';
import {loadCheckoutData, prefillCheckoutData} from 'airborne/air/store/checkout/actions/checkoutData';
import {requestFareFamilies} from 'airborne/air/store/fare_search/actions/fareFamilies';
import {
    flightPriceWithUpdatedKeys,
    clearFlightPriceRequests,
} from 'airborne/air/store/fare_search/actions/flightPrice';
import {getSearchFlightOptionKeys} from 'airborne/air/store/fare_search/selectors';
import {apiError} from 'airborne/common/apiError';
import {showModal} from 'airborne/store/modules/header/actions/modal';
import browserHistory from 'airborne/browserHistory';
import {isAirExchangeFlow} from 'airborne/air/store/exchange/selectors';
import {isAirSeparatedTicketsMode} from "airborne/air/store/pricing/selectors";
import {getTicketIndexes} from "airborne/air/pricing/helpers/separatedTickets";

export function toCheckout() {
    return function (dispatch, getState) {
        if (!isAirSeparatedTicketsMode(getState())) {
            return dispatch({type: types.AIR_TO_CHECKOUT, uuid: generateUUID()});
        }

        getTicketIndexes().forEach(ticketIndex => dispatch({
            type: types.AIR_TO_CHECKOUT,
            ticketIndex,
            uuid: generateUUID()
        }))
    }
}

export function setFareKeys(fareGroupKey, flightOptionKeys, ticketIndex) {
    return {
        type: types.AIR_SET_FARE_KEYS,
        fareGroupKey,
        flightOptionKeys,
        ticketIndex,
    };
}

export const prepareCheckout = () => async (dispatch) => {
    await dispatch(flightPriceWithUpdatedKeys());

    await Promise.all([
        dispatch(loadCheckoutData({rethrow: true})),
        dispatch(requestFareFamilies({rethrow: false})),
    ]);
    dispatch(prefillCheckoutData());
};

export function toBook(fareGroupKey) {
    return async function toBookD(dispatch, getState) {
        const flightOptionKeys = getSearchFlightOptionKeys(getState(), fareGroupKey);

        dispatch(setFareKeys(fareGroupKey, flightOptionKeys));
        dispatch({type: types.AIR_CHECKOUT_LOADING});

        // We want to allow a user to press the Book button
        // even if the flightPrice request for this particular fare group is ongoing.
        // So clearing out current request list prevents false promise resolving,
        // and instead - makes a new flightPrice request
        dispatch(clearFlightPriceRequests());
        try {
            await dispatch(prepareCheckout());
            if (!isAirExchangeFlow(getState())) {
                browserHistory.push('/ui/air/checkout/');
            }
            dispatch(toCheckout());
        }
        catch (error) {
            dispatch(showModal('error', {message: apiError(error)}));
            dispatch({type: types.AIR_CHECKOUT_FAIL});
        }
    };
}
