import gettext from 'airborne/gettext';
import moment from 'moment';
import memoize from 'lodash/memoize';
import omit from 'lodash/omit';
import {
    createSchema,
    required,
    combineValidators,
    combineFormValidators,
    strictOnly,
    checkIsThereCreditCard
} from 'midoffice/newforms/helpers';
import {Schema} from 'midoffice/newforms/schema-stateless';
import {PaymentInstructionsField} from 'midoffice/data/schemas/VPASchema';
import {
    Field,
    BooleanField,
    CharField,
    NumberField,
    ChoiceField,
    PhoneNumberField,
} from 'midoffice/newforms/fields-stateless';
import {validDatePolicy} from 'airborne/checkout2/helpers/checkoutForm';
import {
    CCFirstNameField,
    CCLastNameField,
    AddressField,
    emptyMessage,
    validateZipOrPostCode,
    validateState,
} from './fields';
import {getPhoneCodeChoices} from 'airborne/air/checkout/helpers/phoneCodes';
import {specialCompanyField} from 'airborne/checkout2/fields';
import {getCCNumberSchema, getAcceptedCards} from 'airborne/checkout2/helpers/cardValidators';

const CVV_LENGTH_AX = 4;
const CVV_LENGTH = 3;

export const cityField = {
    ...CharField,
    maxLength: 35
};

function cvvLength(type) {
    return type === 'ax' || type?.value === 'ax' ? CVV_LENGTH_AX : CVV_LENGTH;
}

function cvvError() {
    return gettext('Invalid card security code');
}


export function makeCvvField(cardType) {
    return {
        ...CharField,
        validate: combineValidators(
            CharField.validate,
            (value)=> ((value && value.match(/^\d+$/) && value.length === cvvLength(cardType))
                ? null
                : cvvError()),
        ),
    };
};

function getCardExpirationSchema(validateExpiration) {
    return createSchema({
        fields: {
            year: required(NumberField, emptyMessage),
            month: required(NumberField, emptyMessage),

        },
        isRequired: true,
        emptyMessage,
        isEmpty: function(value) {
            return Field.isEmpty.call(this, value) || !(value.year && value.month);
        },
        validate: function (value, params) {
            const validators = combineValidators(
                Field.validateEmptyValue,
                validateExpiration,
            );
            return validators.call(this, value, params);
        },
    });
}

export function getTspmFields(validateExpiration, isCardholderNameRequired = true) {
    return {
        'credit_card_expiration': getCardExpirationSchema(validateExpiration),
        'credit_card_first_name': isCardholderNameRequired ? required(CCFirstNameField, emptyMessage) : CCFirstNameField,
        'credit_card_last_name': isCardholderNameRequired ? required(CCLastNameField, emptyMessage) : CCLastNameField,
    };
};

export const getPhoneSchema = () => createSchema({
    fields: {
        'country_code': required(
            strictOnly({...ChoiceField, choices: getPhoneCodeChoices()}),
            emptyMessage
        ),
        'number': required(
            strictOnly(PhoneNumberField),
            emptyMessage
        ),
    }
});

const getBillingFieldsList = () => ({
    'credit_card_country_code': required(CharField, emptyMessage),
    'credit_card_state_province_code': CharField,
    'credit_card_company_name': specialCompanyField(30),
    'credit_card_address': required(AddressField, emptyMessage),
    'credit_card_address2': AddressField,
    'credit_card_city': required(cityField, emptyMessage),
    'credit_card_postal_code': required(CharField, emptyMessage),
    'credit_card_phone': required(strictOnly(getPhoneSchema()), emptyMessage),
});

export function getBillingFields({withoutPhone} = {}) {

    let fields = {...getBillingFieldsList()};

    if (withoutPhone) {
        fields = omit(fields, 'credit_card_phone');
    }

    return fields;
}

export function getCardFields(paymentCardsAccepted, validateExpiration, isCardholderNameRequired = true) {
    return {
        'credit_card_number': required(getCCNumberSchema(paymentCardsAccepted), emptyMessage),
        'credit_card_expiration': getCardExpirationSchema(validateExpiration),
        'credit_card_first_name': isCardholderNameRequired ? required(CCFirstNameField, emptyMessage) : CCFirstNameField,
        'credit_card_last_name': isCardholderNameRequired ? required(CCLastNameField, emptyMessage): CCLastNameField,
    };
}

const VpaGuaranteeSchema = createSchema({
    fields: {
        'paid_by': CharField,
        'evoucher': BooleanField,
        'vpa_card_pool_id': required(CharField, emptyMessage),
    }
});

const VpaPaymentSchema = createSchema({
    fields: {
        'paid_by': CharField,
        'vpa_card_pool_id': required(CharField, emptyMessage),
        'vpa_payment_instructions': PaymentInstructionsField,
        'vpa_no_send_fax': BooleanField,
    }
});

const IataSchema = createSchema({
    fields: {
        'paid_by': CharField,
        'evoucher': BooleanField,
    }
});
const HotelProviderPaySchema = createSchema({
    fields: {
        'paid_by': CharField,
        'evoucher': BooleanField,
    }
});
const InvoiceSchema = IataSchema;

const SCHEMA = {
    'vpa_payment': VpaPaymentSchema,
    'vpa_guarantee': VpaGuaranteeSchema,
    'iata': IataSchema,
    'invoice': InvoiceSchema,
    'hotel_provider_pay': HotelProviderPaySchema,
};

function expired(paidPolicy) {
    return paidPolicy === 'G'
        ? gettext('Please check your credit card expiration date. Credit card must be valid at the time of selected check-out date and should not expire in the current month.')
        : gettext('Please check your credit card expiration date. Credit card must be valid until the end of next month.');
}

function validateExpiration(paidPolicy, checkout, value) {
    return !value || validDatePolicy(
        checkout,
        paidPolicy,
        value['year'],
        value['month']
    )
        ? null
        : expired(paidPolicy);
}

const postCodeValidator = (value) => validateZipOrPostCode('credit_card_country_code', 'credit_card_postal_code', value);

export const createCardSchema = memoize(
    function createCardSchema(billing, tspm, paidPolicy, checkout, cvv, paymentCardsAccepted, cardType) {
        const partialValidateExpiration = validateExpiration.bind(
            this, paidPolicy, moment(checkout, 'YYYY-MM-DD'));
        return createSchema({
            fields: {
                'paid_by': CharField,
                'card_index': CharField,
                'central_payment': BooleanField,
                'evoucher': BooleanField,
                'charge_guarantee_card': BooleanField,
                ...(tspm
                    ? getTspmFields(partialValidateExpiration)
                    : getCardFields(paymentCardsAccepted, partialValidateExpiration)),
                ...(cvv
                    ? {
                        'credit_card_identifier': required(makeCvvField(cardType), emptyMessage)
                    }
                    : {}
                ),
                ...(billing ? getBillingFieldsList() : {}),
            },
            validate(value, params) {
                return combineFormValidators(
                    () => validateState('credit_card_country_code', 'credit_card_state_province_code', value),
                    billing ? () => postCodeValidator(value) : ()=> null,
                    checkIsThereCreditCard,
                    Schema.validate.bind(this),
                )(value, params);
            },
        });
    },
    function (billing, tspm, paidPolicy, checkout, cvv, paymentCardsAccepted, cardType) {
        return `${billing}|${tspm}|${paidPolicy}|${checkout}|${cvv}|${getAcceptedCards(paymentCardsAccepted)}|${cardType}`;
    },
);

function selectSchema(type, billing, cardIndex, ...args) {
    const isTspmCard = cardIndex !== null;
    if (type === 'card') {
        return createCardSchema(billing, isTspmCard , ...args);
    }
    return SCHEMA[type];
}

export default selectSchema;
