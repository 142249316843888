'process i18n';
import React from 'react';
import get from 'lodash/get';

import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';

import {bookingShape} from "midoffice/air-bookings/shapes";
import Button from 'midoffice/components/Button';
import {getProviderName} from "midoffice/air-bookings/BookingDetails";
import format from 'midoffice/helpers/format';

import Loader from 'airborne/search2/Loader';

import gettext from 'airborne/gettext';

import PT from "prop-types";

const TICKET_STATUS_TICKETED = 'TICKETED';
const TICKET_TYPE_E_TICKET = 'E';

export default class ManageTicketsModal extends React.Component {
    static propTypes = {
        booking: bookingShape,
        onHide: PT.func.isRequired,
        loading: PT.bool,
        preload: PT.func.isRequired,
        errorMessage: PT.string,
        isExternalBooking: PT.bool,
    };

    state = {
        selectedTickets: []
    }

    componentDidMount() {
        this.props.preload();
    }

    renderPNRInfo() {
        const {booking} = this.props;

        return (
            <>
                <p>{gettext('PNR')}: <b>{get(booking, 'pnr.id', '')}</b></p>
                <p>{gettext('Provider')}: <b>{getProviderName(booking)}</b></p>
            </>
        );
    }

    onTicketsSelectionChange = (e) => {
        const {selectedTickets} = this.state;

        const value = e.target.value;

        if (selectedTickets.includes(value)) {
            return this.setState({
                selectedTickets: selectedTickets.filter(ticketNumber => ticketNumber !== value)
            });
        }

        this.setState({
            selectedTickets: [...selectedTickets, value]
        });
    }

    renderTicket = (ticket) => {
        const {selectedTickets} = this.state;

        const {tktType: type, number, status, date} = ticket;

        const operatable = status === TICKET_STATUS_TICKETED && type === TICKET_TYPE_E_TICKET;

        return (<tr key={number}>
            <td>
                {operatable && (
                    <input
                        type="checkbox"
                        name="tickets[]"
                        onChange={this.onTicketsSelectionChange}
                        value={number}
                        checked={selectedTickets.includes(number)}
                        className="documents-manage__checkbox"
                    />
                )}
            </td>
            <td>{number}</td>
            <td>{type}</td>
            <td>{status}</td>
            <td>{format.date(date)}</td>
        </tr>);
    }

    renderTickets() {
        const {tickets} = this.props;

        if (tickets.length === 0) {
            return <div className="well well-sm">No active tickets found</div>;
        }

        return (
            <Table bordered={false} hover className="documents-manage__table">
                <thead>
                    <tr>
                        <th></th>
                        <th>{gettext('Number')}</th>
                        <th>{gettext('Type')}</th>
                        <th>{gettext('Status')}</th>
                        <th>{gettext('Date')}</th>
                    </tr>
                </thead>
                <tbody>
                    {tickets.map(this.renderTicket)}
                </tbody>
            </Table>
        )
    }

    voidTickets = () => {
        const {requestTicketsVoiding} = this.props;
        const {selectedTickets} = this.state;
        requestTicketsVoiding(selectedTickets);
    }

    refundTickets = () => {
        const {requestTicketsRefund} = this.props;
        const {selectedTickets} = this.state;
        requestTicketsRefund(selectedTickets);
    }

    renderActionButtons = () => {
        const {voidRequestLoading, refundRequestLoading, tickets} = this.props;

        const {selectedTickets} = this.state;
        const disableActionButtons = selectedTickets.length === 0;

        if (tickets.length === 0) {
            return null;
        }

        return (<div className="documents-manage__action-buttons">
            <Button onClick={this.voidTickets} className="documents-manage--refund-btn" disabled={disableActionButtons}>
                {voidRequestLoading ? <Loader light/> : gettext('Void')}
            </Button>
            <Button onClick={this.refundTickets} disabled={disableActionButtons}>
                {refundRequestLoading ? <Loader light/> : gettext('Refund')}
            </Button>
        </div>);
    }

    renderErrors(errors) {
        return (
            <div className="alert alert-danger">
                {errors}
            </div>
        );
    }

    renderBody() {
        return (
            <div className="documents-manage">
                {this.renderPNRInfo()}
                {this.renderTickets()}
                {this.renderActionButtons()}
            </div>
        );
    }

    render() {
        const {onHide, loading, errorMessage} = this.props;

        return (
            <Modal onHide={onHide} show>
                <Modal.Header closeButton>
                    <Modal.Title>Manage Tickets</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {loading
                        ? <Loader light wrapped/>
                        : errorMessage
                            ? this.renderErrors(errorMessage)
                            : this.renderBody()
                    }
                </Modal.Body>

                <Modal.Footer>
                    <Button bsStyle="light" onClick={onHide}>{gettext('Close')}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
