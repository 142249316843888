import {loadDirectSellCheckoutData} from 'airborne/store/modules/checkout_cars/actions/checkoutData';
import browserHistory from 'airborne/browserHistory';
import generateUUID from 'airborne/checkout2/helpers/uuid';


export function toDirectSell() {
    return {type: 'CARS_TO_DIRECT_SELL_CHECKOUT', uuid: generateUUID()};
}

export function bookDirectSell() {
    return async function bookRateD(dispatch) {
        dispatch({type: 'CARS_CHECKOUT_LOADING'});
        try {
            dispatch(loadDirectSellCheckoutData());

            browserHistory.push('/ui/cars/direct_sell/');

            dispatch(toDirectSell());
        }
        catch (error) {
            dispatch({type: 'CARS_CHECKOUT_FAIL'});
        }
        finally {
            dispatch({type: 'CARS_CHECKOUT_LOADING_FINISHED'});
        }
    };
}
