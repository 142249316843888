'process i18n';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import capitalize from 'lodash/capitalize';
import cx from 'classnames';

import gettext from 'airborne/gettext';
import settings from 'airborne/settings';

import format from 'midoffice/helpers/format';
import {getTimezoneAbbr} from 'midoffice/helpers/date';
import {bookingShape} from 'midoffice/air-bookings/shapes.js';
import User from 'midoffice/components/User';
import {pnrLinkOrPlain} from 'midoffice/helpers/pnrLinkOrPlain.js';
import BookingDatatable from 'midoffice/components/BookingDatatable';
import BookingIssues from 'midoffice/bookings/records/BookingIssues';
import BookingInfoTabs from 'midoffice/bookings/records/BookingInfoTabs';
import {PROVIDER_NAMES} from './constants';
import {hasAccess} from 'midoffice/helpers/permission';
import systemData from 'airborne/systemData';
import {Trans} from 'react-i18next';

const getProviderType = (booking) => {
    const {provider, fareGroup: {isNdc}} = booking;

    if (provider === 'travelfusion' && !isNdc) {
        return 'LCC';
    }

    if (isNdc) {
        return 'NDC';
    }

    return 'GDS';
};

export const getProviderName = (booking) => {
    const {provider} = booking;

    return PROVIDER_NAMES[provider] || capitalize(provider);
};

const getBookingSource = (booking) => {
    const type = getProviderType(booking);
    const name = getProviderName(booking);

    return `${type} - ${name}`;
};

export class BookingHistory extends React.Component {
    static propTypes = {
        hideEmails: PropTypes.bool,
        booking: bookingShape,
    };

    renderAgency() {
        const {booking: {gdsProfile: {officeId, gdsName}}} = this.props;
        const link = `${systemData.common.ASB_ADMIN_URL}/agencies/by_oid?gds_name=${gdsName}&oid=${officeId}`;
        const label = officeId || '-';
        const isLink = hasAccess('admin:login') && hasAccess('midoffice:agencies:*');

        return isLink
            ? (<a href={(link)} target="_blank">
                {label}
            </a>)
            : label;
    }

    renderUser(user) {
        const label = <User {...user} />;
        const isLink = hasAccess('admin:login') && hasAccess('midoffice:groups:*');
        const link = `${systemData.common.ASB_ADMIN_URL}/users/allusers/${encodeURIComponent(user.email)}`;

        return isLink
            ? (<a href={(link)} target="_blank">
                {label}
            </a>)
            : label;
    }

    renderDateTime(date) {
        date = moment(date);
        const tzOffset = date.toDate().getTimezoneOffset();
        const tz = getTimezoneAbbr(tzOffset);
        return (
            <span>
                {date.format('DD MMM YYYY hh:mm A')} {tz}
            </span>
        );
    }

    renderResidualEmdsEvent(data, user) {
        const {hideEmails} = this.props;
        const {status, lastUpdate} = data;

        if (status === 'pending') {
            return null;
        }

        const labelByStatus = {
            'success': gettext('Refunded Successfully'),
            'failed': gettext('Refund failure'),
        };

        const detailsByStatus = {
            'success': gettext('Residual value EMD was refunded successfully.'),
            'failed': gettext('There is a residual value EMD, but we failed to automatically refund it. Please try to take care of it manually.'),
        };

        const labelClass = cx({'highlight-red': status === 'failed'});

        return (<tr>
            <td><span className={labelClass}>{labelByStatus[status]}</span></td>
            <td>{this.renderDateTime(lastUpdate)}</td>
            {!hideEmails && (<td>{this.renderUser(user)}</td>)}
            <td>{this.renderAgency()}</td>
            <td>{user.sign || '–'}</td>
            <td><span>{detailsByStatus[status]}</span></td>
        </tr>);
    }

    renderResidualEmdsEvents(residualEmds, user) {
        return residualEmds.map(
            residualEmdsItem => this.renderResidualEmdsEvent(residualEmdsItem, user)
        );
    }

    renderEvent (label, date, user, cancellationStatus) {
        if (!user || cancellationStatus === 'failed') {
            return null;
        }

        const {hideEmails} = this.props;
        return (<tr>
            <td><span>{label}</span></td>
            <td>{this.renderDateTime(date)}</td>
            {!hideEmails && (<td>{this.renderUser(user)}</td>)}
            <td>{this.renderAgency()}</td>
            <td>{user.sign || '–'}</td>
            <td>-</td>
        </tr>);
    }

    render () {
        const {
            hideEmails,
            booking: {
                bookerUser,
                cancelledByUser,
                createdAt,
                cancelledAt,
                cancellationStatus,
                residualEmds,
            },
        } = this.props;

        return (<div>
            <table className="table">
                <tbody>
                    <tr>
                        <th colSpan={2}>{gettext('Booking History:')}</th>
                        {!hideEmails && (<th>{gettext('Booker email:')}</th>)}
                        <th>{gettext('OID / PCC:')}</th>
                        <th>{gettext('Sign / EPR:')}</th>
                        <th>{gettext('Details / Comments:')}</th>
                    </tr>
                    {residualEmds && this.renderResidualEmdsEvents(residualEmds, bookerUser)}
                    {this.renderEvent(gettext('Canceled'), cancelledAt, cancelledByUser, cancellationStatus)}
                    {this.renderEvent(gettext('Created'), createdAt, bookerUser)}
                </tbody>
            </table>
        </div>);
    }
}


export default class BookingDetails extends React.Component {
    static propTypes = {
        booking: bookingShape,
        hideEmails: PropTypes.bool.isRequired,
    }

    state = {
        activeTab: 'details',
    }

    formatFlightInformation(segment) {
        const {carrier, airplane, operatingCarrier} = segment;
        const {code, name: carrierName, flightNumber} = carrier;
        const flight = `${code}-${flightNumber}`;
        const shouldShowOperatingCarrier = operatingCarrier && code !== operatingCarrier.code;

        return (
            <>
                <Trans i18nKey="airBookingDetailsFlightInfo" values={{flight, carrierName}} components={{ b: <b /> }} />
                {shouldShowOperatingCarrier  && (
                    <>
                        {' '}/{' '}
                        <Trans i18nKey="airBookingDetailsFlightInfoOperated"
                               values={{ocName: operatingCarrier.name}}
                               components={{ b: <b /> }}
                        />
                    </>
                )}
                {airplane && ` / ${airplane}`}
            </>
        );
    }

    handleTabSelect = (activeTab) => this.setState({activeTab});

    renderService(service, idx) {
        const {amount, currency, description, details} = service;
        return (
            <li key={idx}><strong>{amount} {currency}</strong> - {description || details}</li>
        );
    }

    renderDetails() {
        const {booking} = this.props;
        const {
            caseNumber,
            system,
            pnr,
            company,
            confirmation,
            farelogixPcc,
            travelers,
            airplusDbiSuccess,
            amexBtaFileCreationDate,
        } = booking;

        const gds = format.airProviderCode(booking.gdsProfile.gdsName);
        const {
            airlineCode,
            airlineLocator,
            farelogixLocator,
            ticketNumbers,
            emdNumbers,
            services,
        } = confirmation;
        const bookingDetails = [
            [gettext('PNR No'), pnrLinkOrPlain(pnr?.id, gds, company.id, system, booking.pnr?.pnrCreated, true, true)],
            [gettext('UUID No'), booking.id],
            [gettext('Case No'), (caseNumber || '').toUpperCase()],
            [gettext('API Consumer ID'), booking.apiApp && booking.apiApp.consumerId || '-'],
            [`${gettext('Airline Locator')}${airlineCode ? ` (${airlineCode})` : ''}`, airlineLocator],
            [gettext('Booking Source'), getBookingSource(booking)],
            [gettext('Farelogix PCC'), farelogixPcc],
            [gettext('Farelogix Locator'), farelogixLocator],
            [gettext('Ticket numbers'), ticketNumbers ? ticketNumbers.map((ticket) => (ticket.number)).join(', ') : '-'],
            [gettext('EMD numbers'), emdNumbers ? emdNumbers.map((emdNumber) => (emdNumber.number)).join(', ') : '-'],
            [gettext('Configured CRR shared status'), (
                (amexBtaFileCreationDate || airplusDbiSuccess) && gettext('Success') ||
                (amexBtaFileCreationDate === false && gettext('Failure')) ||
                '-'
            )],
            [gettext('Operational Team Email'), booking.operationalTeamEmail || (<em>{gettext('Not available from profile')}</em>)],
            [gettext('Traveler Email'), travelers?.[0].email],
            [gettext('TSPM traveler ID'), travelers?.[0].tspmId || '---'],
            [gettext('TSPM company ID'), company.tspmId || '---'],
        ];

        const firstSegment = booking.fareGroup.originDestinations[0].segments[0];

        const flightInformation = [
            [gettext('Flight'), this.formatFlightInformation(firstSegment)],
            [gettext('Cabin'), settings.CABIN_CLASSES[firstSegment.cabin]]
        ];

        const formatedServices = services?.length
            ? (<ul className="list-unstyled">
                {services.map(this.renderService)}
            </ul>)
            : <ul className="list-unstyled">{gettext('No additional services were booked')}</ul>;

        const columns = [
            {title: gettext('Booking Details:'), data: bookingDetails},
            {title: gettext('Flight Information:'), data: flightInformation},
            {title: gettext('Requested Services:'), specialFormat: formatedServices},
        ];

        return <BookingDatatable columns={columns}/>;
    }

    renderHistory() {
        const {booking, hideEmails} = this.props;
        return (<BookingHistory booking={booking} hideEmails={hideEmails} />);
    }

    renderIssues() {
        // TODO: Remove isAir prop once GG-32652 is done
        return (
            <BookingIssues booking={this.props.booking} isAir />
        );
    }

    renderActiveTab() {
        switch (this.state.activeTab) {
            case 'details':
                return this.renderDetails();
            case 'issues':
                return this.renderIssues();
            case 'history':
                return this.renderHistory();
        }
    }

    render() {
        const {activeTab} = this.state;
        return (<tr className="warning">
            <td>&nbsp;</td>
            <td colSpan={100} style={{padding: '15px 5px'}}>
                <BookingInfoTabs
                    booking={this.props.booking}
                    activeTab={activeTab}
                    handleTabSelect={this.handleTabSelect}
                    // TODO: Remove isAir prop once GG-32652 is done
                    isAir
                />
                <div className="tabs__nav__content">
                    {this.renderActiveTab()}
                </div>
            </td>
        </tr>);
    }
}
