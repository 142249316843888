import * as actionTypes from '../actionTypes';
import air, {handleApiError} from 'airborne/store/modules/search_hotels/actions/air';
import {getPnrProfile} from 'airborne/store/modules/homepage/selectors/pnrProfile';
import {
    serializeFTNumbers,
    getUniqueFareKey,
    extractFlightOptionKeys,
} from 'airborne/air/fare_search/helpers';
import {
    getFareGroupKey,
    getFlightOptionKeys,
    getSearchFlightOptionKeys,
    isSelectedFlightPriceAvailable,
    getFlightPriceRequests,
    getFlightPriceByKeys, getFareKeys, getFareGroupStoreKey, getSelectedSeatMaps,
} from 'airborne/air/store/fare_search/selectors';
import {getSelectedOptionalServices, getCheckoutFrequentFlyerNumbers, shouldUpdateFlightPriceByKeys} from 'airborne/air/store/checkout/selectors';
import {parse} from 'airborne/types';
import {dropEmpty} from 'midoffice/helpers/urlParams';
import {apiError} from 'airborne/air/fare_search/helpers/apiError';
import {setFareKeys} from 'airborne/air/store/fare_search/actions/toBook';
import {getCurrency} from 'airborne/store/modules/header/selectors/i18n';
import {getAirExchangeBookingKey, isAirManualExchange} from 'airborne/air/store/exchange/selectors';
import {getSelectedConfiguration} from 'airborne/store/modules/homepage/selectors/tspm';
import {addPolicyRules, parsePolicyRules} from 'airborne/air/store/fare_search/actions/searchAir';
import {isAirSeparatedTicketsMode} from "airborne/air/store/pricing/selectors";
import {setSeatMap} from "airborne/air/store/fare_search/actions/seatMap";

const addFlightPriceRequest = (requestId) => ({type: actionTypes.AIR_FLIGHT_PRICE_ADD_REQUEST, requestId});
const removeFlightPriceRequest = (requestId) => ({type: actionTypes.AIR_FLIGHT_PRICE_REMOVE_REQUEST, requestId});
export const clearFlightPriceRequests = () => ({type: actionTypes.AIR_FLIGHT_PRICE_CLEAR_REQUESTS});

const setFlightPriceLastRequestParams = (params) => ({type: actionTypes.AIR_FLIGHT_PRICE_SET_LAST_REQUEST_PARAMS, params});
export const clearFlightPriceErrors = () => ({type: actionTypes.AIR_FLIGHT_PRICE_CLEAR_ERRORS});

function flightPriceFailure(response) {
    return function flightFailureD(dispatch, getState) {
        const notAvailable = !isSelectedFlightPriceAvailable(getState());
        const errors = apiError(response, notAvailable);
        dispatch({type: actionTypes.AIR_FLIGHT_PRICE_FAIL, errors});
    };
}

const getFlightPrice = ({fareGroupKey, flightOptionKeys, ticketIndex, rethrow, bestPricing}) => async (dispatch, getState) => {
    const ftNumbers = getCheckoutFrequentFlyerNumbers(getState());
    const selectedServicesKeys = getSelectedOptionalServices(getState(), ticketIndex);
    const currency = getCurrency(getState());
    const isManualExchange = isAirManualExchange(getState());

    dispatch({type: actionTypes.AIR_FLIGHT_PRICE_LOADING});
    try {
        const response = await air('POST', '/air/pricing/', dropEmpty({
            'currency': currency || 'EUR',
            'configuration_id': getSelectedConfiguration(getState()) || getPnrProfile(getState(), 0).company,
            'fare_group_key': fareGroupKey,
            'flight_option_keys': flightOptionKeys,
            'optional_service_keys': selectedServicesKeys?.length ? selectedServicesKeys : null,
            'exchange_key': getAirExchangeBookingKey(getState()),
            'is_manual_exchange': isManualExchange,
            'best_pricing': bestPricing,
            'travelers': [
                {
                    'traveler_type': 'adult',
                    'ff_numbers': serializeFTNumbers(ftNumbers),
                },
            ]
        }));
        const storeKey = getUniqueFareKey({fareGroupKey, flightOptionKeys});

        const requestParams = {ftNumbers};
        dispatch(setFlightPriceLastRequestParams({[storeKey]: requestParams}));

        const policyRules = parsePolicyRules(response);
        dispatch(addPolicyRules(policyRules))

        // At the moment we do not know what to do with additional fare
        // groups. We pick first but there are no secret reason for it.
        const data = {[storeKey]: parse('flightPrice', response.fare_groups[0])};

        dispatch({type: actionTypes.AIR_FLIGHT_PRICE_LOADED, data});
        return Promise.resolve(response);
    }
    catch (response) {
        try {
            handleApiError(response);
            dispatch({type: actionTypes.AIR_FLIGHT_PRICE_ABORT});
        }
        catch {
            dispatch(flightPriceFailure(response));
            if (rethrow) {
                throw response;
            }
        }
    }
};

export const requestSingleFlightPrice = ({fareGroupKey, flightOptionKeys, ticketIndex, rethrow, bestPricing, keepLoading} = {}) =>
    async (dispatch, getState) => {
        const key = getUniqueFareKey({fareGroupKey, flightOptionKeys});
        const currentRequests = getFlightPriceRequests(getState());
        const shouldUpdateFlightPrice = shouldUpdateFlightPriceByKeys(getState(), fareGroupKey, flightOptionKeys, ticketIndex);
        const shouldSkipRequest = !bestPricing && (currentRequests.includes(key) || !shouldUpdateFlightPrice);

        if (shouldSkipRequest) {
            return Promise.resolve();
        }

        dispatch(addFlightPriceRequest(key));
        try {
            return await dispatch(getFlightPrice({rethrow, fareGroupKey, flightOptionKeys, ticketIndex, bestPricing}));
        }
        finally {
            dispatch(removeFlightPriceRequest(key));
            const afterRequests = getFlightPriceRequests(getState());

            if (!afterRequests.length && !keepLoading) {
                dispatch({type: actionTypes.AIR_FLIGHT_PRICE_LOAD_END});
            }
        }
    };

export const flightPrice = ({ticketIndex, rethrow, bestPricing, keepLoading} = {}) => async (dispatch, getState) => {
    const fareGroupKey = getFareGroupKey(getState(), ticketIndex);
    const flightOptionKeys = getFlightOptionKeys(getState(), ticketIndex);

    await dispatch(requestSingleFlightPrice({fareGroupKey, flightOptionKeys, ticketIndex, rethrow, bestPricing, keepLoading}));
    return getFlightPriceByKeys(getState(), fareGroupKey, flightOptionKeys);
};

export const requestFlightPriceWithKeys = (fareGroupKey, flightOptionKeys) => async (dispatch) => {
    dispatch(setFareKeys(fareGroupKey, flightOptionKeys));
    await dispatch(flightPrice());
};

export const requestFlightPriceWithFareGroupKey = fareGroupKey => async (dispatch, getState) => {
    const flightOptionKeys = getSearchFlightOptionKeys(getState(), fareGroupKey);

    return dispatch(requestFlightPriceWithKeys(fareGroupKey, flightOptionKeys));
};

export const flightPriceWithUpdatedKeys = ({
    ticketIndex,
    rethrow = true,
    bestPricing,
    keepLoading,
} = {}) => async (dispatch, getState) => {
    const seatMaps = getSelectedSeatMaps(getState(), ticketIndex);

    const flightPriceData = await dispatch(flightPrice({ticketIndex, rethrow, bestPricing, keepLoading}));
    // At the moment we do not know what to do with additional fare
    // groups. We pick first but there is no secret reason for it.
    const {fareGroupKey, originDestinations} = flightPriceData;
    const flightOptionKeys = extractFlightOptionKeys(originDestinations);
    const storeKey = getUniqueFareKey({fareGroupKey, flightOptionKeys});
    const data = {[storeKey]: flightPriceData};
    dispatch({type: actionTypes.AIR_FLIGHT_PRICE_LOADED, data});

    dispatch(setFareKeys(fareGroupKey, flightOptionKeys, ticketIndex));
    dispatch(setSeatMap({[storeKey]: seatMaps}))
    return Promise.resolve(flightPriceData);
};

export const updateFlightPrice = () => async (dispatch, getState) => {
    if (!isAirSeparatedTicketsMode(getState())) {
        return dispatch(flightPriceWithUpdatedKeys());
    }

    return await Promise.all([
        dispatch(flightPriceWithUpdatedKeys({ticketIndex: 0})),
        dispatch(flightPriceWithUpdatedKeys({ticketIndex: 1})),
    ]);
};
