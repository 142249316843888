import * as types from '../actionTypes';
import * as searchTypes from 'airborne/air/store/fare_search/actionTypes';
import {getErrorMessages} from 'airborne/helpers/apiError';
import {showModal} from 'airborne/store/modules/header/actions/modal';
import {
    prepareCheckout,
    setFareKeys,
} from 'airborne/air/store/fare_search/actions/toBook';
import {toCheckout} from 'airborne/air/store/fare_search/actions/toBook';
import {isAirManualExchange} from 'airborne/air/store/exchange/selectors';
import {makeManualExchange} from 'airborne/air/store/exchange/actions';
import {getSeparatedTicketsCurrentOD, isAirSeparatedTicketsMode} from "airborne/air/store/pricing/selectors";
import {
    resetSeparatedFare,
    saveSeparatedFare,
    setSeparatedTicketsOD
} from "airborne/air/store/pricing/actions/separatedTickets";
import {requestFaresPricing} from "airborne/air/store/pricing/actions/request";
import {flightPriceWithUpdatedKeys} from "airborne/air/store/fare_search/actions/flightPrice";
import {loadCheckoutDataSeparatedTickets, prefillCheckoutData} from "airborne/air/store/checkout/actions/checkoutData";
import {requestFareFamilies} from "airborne/air/store/fare_search/actions/fareFamilies";

const resetAirPricing = () => ({type: types.AIR_PRICING_RESET});
export const toAirPricing = () => ({type: types.AIR_PRICING_TO_PRICING});
export const toDefaultAirPricing = () => (dispatch) => {
    dispatch(resetAirPricing());
    dispatch(toAirPricing());
};

export const toNextBookingStep = (fareGroupKey, flightOptionKeys) => async (dispatch, getState) => {
    const isSeparatedTicketsMode = isAirSeparatedTicketsMode(getState());
    const separatedTicketsOD = getSeparatedTicketsCurrentOD(getState());
    const isSelectionFulfilled = separatedTicketsOD === 1;

    if (isSeparatedTicketsMode && !isSelectionFulfilled) {
        dispatch(toNextSeparatedFare(fareGroupKey, flightOptionKeys));
        await dispatch(requestFaresPricing());
    } else {
        isSeparatedTicketsMode && dispatch(saveSeparatedFare(fareGroupKey));

        dispatch(toAirCheckout(fareGroupKey, flightOptionKeys));
    }
}

export const toNextSeparatedFare = (fareGroupKey, flightOptionKeys) => (dispatch, getState) => {
    const currentOD = getSeparatedTicketsCurrentOD(getState());

    dispatch(setFareKeys(fareGroupKey, flightOptionKeys, currentOD));

    dispatch(saveSeparatedFare(fareGroupKey));

    dispatch(setSeparatedTicketsOD(currentOD + 1));
}

export const changeSeparatedFareSelection = (OD) => (dispatch) => {
    dispatch(setSeparatedTicketsOD(OD));
    dispatch(resetSeparatedFare(OD));
    dispatch(requestFaresPricing());
}

export const prepareSeparatedTicketsCheckout = () => async (dispatch) => {
    await Promise.all([
        dispatch(flightPriceWithUpdatedKeys({ticketIndex: 0})),
        dispatch(flightPriceWithUpdatedKeys({ticketIndex: 1})),
        dispatch(loadCheckoutDataSeparatedTickets({rethrow: true})),
    ]);
    // we should wait for fresh fare keys and only then request fare families
    // It's necessary for displaying seat maps, otherwise seat map will be stored
    // with outdated keys, so they are initially unavailable
    await Promise.all([
        dispatch(requestFareFamilies({ticketIndex: 0})),
        dispatch(requestFareFamilies({ticketIndex: 1})),
    ]);

    dispatch(prefillCheckoutData());
}

export const toAirCheckout = (fareGroupKey, flightOptionKeys) => async (dispatch, getState) => {
    const ticketIndex = getSeparatedTicketsCurrentOD(getState());
    const separatedTicketsMode = isAirSeparatedTicketsMode(getState());

    dispatch(setFareKeys(fareGroupKey, flightOptionKeys, ticketIndex));

    dispatch({type: searchTypes.AIR_CHECKOUT_LOADING});
    try {
        if (separatedTicketsMode) {
            await dispatch(prepareSeparatedTicketsCheckout());
        } else {
            await dispatch(prepareCheckout());
        }

        if (isAirManualExchange(getState())) {
            dispatch(makeManualExchange());
        }
        else {
            dispatch(toCheckout());
        }
    }
    catch (response) {
        dispatch(showModal('error', {message: getErrorMessages(response)}));
        dispatch({type: searchTypes.AIR_CHECKOUT_FAIL});
    }
};
