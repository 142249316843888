import React from 'react';

import gettext from 'airborne/gettext';
import systemData from 'airborne/systemData';
import {hasAccess} from 'midoffice/helpers/permission';
import {PNRCopyButton} from "airborne/header2/PNRCopyButton";

export function pnrLinkOrPlain(pnr, gds, company, system, createdByAsb, isAir, enablePnrCopyBtn, copyBtnClass) {
    if (!pnr) {
        return isAir ? ' ' : gettext('N/A');
    }

    let formattedPnr = `[${gds}]\u00A0${pnr}`;
    if (createdByAsb) {
        formattedPnr += ` (${gettext('Created by ASB')})`;
    }

    const isLink = (
        hasAccess('admin:login')
        && hasAccess('midoffice:tools:pnr_tools:edit')
    );

    return (
        <>
            {isLink ? (
                <a href={`${systemData.common.ASB_ADMIN_URL}/pnr-tool/${company}/${pnr}/${system}`} target={'_blank'}>
                    {formattedPnr}
                </a>
            ) : formattedPnr}
            {enablePnrCopyBtn && ' '}
            {enablePnrCopyBtn && <PNRCopyButton pnr={pnr} copyBtnClass={copyBtnClass} />}
        </>
    );
}
