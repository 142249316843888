import gettext from 'airborne/gettext';

const CHOICES = [0.1, 0.5, 1, 2, 5, 10, 15, 20, 25, 30, 50];
const CAR_CITY_CHOICES = [5, 10, 15, 20, 25, 30, 50];
const CITY_TYPE = 'c';
const AIRPORT_TYPE = 'a';


function isCity(destination) {
    return destination && destination.type === CITY_TYPE;
}

export function isAirport(destination) {
    return destination && destination.type === AIRPORT_TYPE;
}

function inUnits(distance, unit) {
    return unit === 'KM'
        ? gettext('{count} km', {count: distance})
        : gettext('{count} miles', {count: distance});
}

function addLabels(list, unit) {
    return list.map((distance)=> (
        distance
            ? [distance, inUnits(distance, unit)]
            : [distance, gettext('Only')]
    ));
}

export function getCarsDistanceChoices(unit, destination, gds) {
    if (!destination) return null;
    if (isAirport(destination) && gds !== 'sabre') return null;
    if (isCity(destination)) return addLabels(CAR_CITY_CHOICES, unit);

    return addLabels(CHOICES, unit);
}
