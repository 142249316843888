import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import gettext from 'airborne/gettext';
import Row from 'react-bootstrap/Row';

import {getAirSearchType} from 'airborne/store/modules/homepage/selectors/homepage';
import {airChangeSearchType} from 'airborne/store/modules/homepage/actions';
import {getAirExchangeModifyFlags, isAirExchangeActive} from 'airborne/air/store/exchange/selectors';

import {AIR_SEARCH_TYPES} from '../types';

const TabButton = ({
    type,
    active,
    onClick,
    children,
    disabled,
    testId,
}) => {
    const getClasses = (active) =>
        classnames('btn', {'ag-home-form__switcher--active': active});

    const handleClick = () => {
        onClick(type);
    };

    return (
        <button
            className={getClasses(active)}
            onClick={handleClick}
            disabled={disabled}
            data-testid={testId}
        >
            {children}
        </button>
    );
};

TabButton.propTypes = {
    children: PropTypes.node,
    type: PropTypes.string,
    active: PropTypes.bool,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    testId: PropTypes.string,
};

@connect(state => ({
    searchType: getAirSearchType(state),
    modifySearchFlags: getAirExchangeModifyFlags(state),
    isAirExchange: isAirExchangeActive(state),
}),
{
    changeSearchType: airChangeSearchType,
})
export default class AirSearchTabs extends React.Component {

    static propTypes = {

        // From store state
        searchType: PropTypes.string,
        modifySearchFlags: PropTypes.oneOfType([
            PropTypes.shape({
                availabilityFlow: PropTypes.bool,
                fareSearchFlow: PropTypes.bool
            }),
            PropTypes.oneOf([null]),
        ]),
        isAirExchange: PropTypes.bool,

        // From store dispatch
        changeSearchType: PropTypes.func,
    }

    componentDidMount() {
        const {
            isAirExchange,
            changeSearchType,
            modifySearchFlags
        } = this.props;
        const availabilityFlow = modifySearchFlags?.availabilityFlow;
        const fareSearchFlow = modifySearchFlags?.fareSearchFlow;

        if (isAirExchange && (fareSearchFlow && !availabilityFlow)) {
            changeSearchType(AIR_SEARCH_TYPES.FLIGHT);
        }
    }

    onTabClick = (type) => {
        const {changeSearchType} = this.props;

        changeSearchType(type);
    }

    render() {
        const {
            searchType,
            isAirExchange,
            modifySearchFlags
        } = this.props;
        const availabilityFlow = modifySearchFlags?.availabilityFlow;
        const fareSearchFlow = modifySearchFlags?.fareSearchFlow;

        return (
            <Row>
                <div className="ag-home-form__switcher">
                    <TabButton
                        onClick={this.onTabClick}
                        type={AIR_SEARCH_TYPES.AVAILABILITY}
                        active={AIR_SEARCH_TYPES.AVAILABILITY === searchType}
                        disabled={isAirExchange && !availabilityFlow}
                        testId="air-availability-search-tab-radio"
                    >
                        {gettext('Availability Search')}
                    </TabButton>
                    <TabButton
                        onClick={this.onTabClick}
                        type={AIR_SEARCH_TYPES.FLIGHT}
                        active={AIR_SEARCH_TYPES.FLIGHT === searchType}
                        disabled={isAirExchange && !fareSearchFlow}
                        testId="air-fare-search-tab-radio"
                    >
                        {gettext('Fare Search')}
                    </TabButton>
                </div>
            </Row>
        );
    }
};
