import gettext from 'airborne/gettext';

import some from 'lodash/some';
import partial from 'lodash/partial';
import groupBy from 'lodash/groupBy';
import identity from 'lodash/identity';
import trim from 'lodash/trim';


import {CharField} from 'midoffice/newforms/fields-stateless';
import {combineValidators, notAllowedValues}  from 'midoffice/newforms/helpers';

import {getMaxPnrCount} from 'airborne/store/modules/homepage/selectors/pnrProfile';
import {parsePnrInput} from 'airborne/air/shared/helpers/pnrInput';

const PNR_RE = /^[A-Z0-9]{6}$/;

function findNonUniq(list) {
    return Object.entries(groupBy(list))
        .filter(([, list])=> list.length > 1)
        .map(([pnr])=> pnr);

}

function validatePNRFormat(value) {
    return PNR_RE.test(value)
        ? null
        : gettext('Invalid PNR record locator {value}', {value});
}

function validatePNRListFormat(value) {
    const listValue = parsePnrInput(value || '');
    const formatErrors = listValue.map(validatePNRFormat).filter(identity);
    if (formatErrors.length) {
        return formatErrors;
    }
    return null;

}

function validateUniq(value) {
    const listValue = parsePnrInput(value || '');
    const doubles = findNonUniq(listValue);
    if (doubles.length) {
        return gettext('repeatPnrError', {
            pnr: doubles.join(', '), count: doubles.length
        });
    }
    return null;
}

function validatePNRCount(pnrs, canBeMulti=true, value) {
    const listValue = parsePnrInput(value || '');
    if (!canBeMulti && listValue.length > 1) {
        return gettext('You could add only 1 PNR');
    }
    if ((listValue.length + pnrs.length) > getMaxPnrCount()) {
        return gettext('You could only add up to {max} PNRs', {max: getMaxPnrCount()});
    }
    return null;
}

function comparePnr(pnr, otherPnrs) {
    const otherList = parsePnrInput(otherPnrs || '');
    return some(
        otherList,
        otherPnr=> pnr.toUpperCase() === trim(otherPnr).toUpperCase()
    );
}

export function makePNRField(pnrs, canBeMulti) {
    return notAllowedValues(pnrs, {
        ...CharField,
        emptyMessage: ()=> gettext('This field is required'),
        notAllowedValuesMessage: ()=> gettext('This PNR has already been selected.'),
        validate: combineValidators(
            validateUniq,
            partial(validatePNRCount, pnrs, canBeMulti),
            validatePNRListFormat,
            CharField.validate,
        ),
    }, comparePnr);
}
