export function getBookingTickets(state) {
    return state.airDocumentsManage.tickets.data;
}

export function isTicketListLoading(state) {
    return state.airDocumentsManage.tickets.loading;
}

export function getTicketListErrors(state) {
    return state.airDocumentsManage.tickets.errors;
}

export function isVoidRequestLoading(state) {
    return state.airDocumentsManage.voiding.loading;
}

export function isRefundRequestLoading(state) {
    return state.airDocumentsManage.refund.loading;
}
