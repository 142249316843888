import ModifyModal from 'midoffice/air-bookings/modify/ModifyModal';
import {connect} from 'react-redux';
import {getAirBooking, isLoading} from 'midoffice/air-bookings/selectors';
import {isAirExchangeActive, isAirExchangeLoading} from 'airborne/air/store/exchange/selectors';
import {fetchBooking} from 'midoffice/air-bookings/modify/actions';
import {startAirExchange} from 'airborne/air/store/exchange/actions';

const Modal = connect((state, {uid}) => {
    const booking = getAirBooking(state, uid);
    const airExchangeLoading = isAirExchangeLoading(state);
    const airExchangeActive = isAirExchangeActive(state);

    return {
        loading: isLoading(state),
        booking,
        airExchangeLoading,
        airExchangeActive,
        isExternalBooking: false,
    };
}, (dispatch, {uid}) => ({
    preload: () => dispatch(fetchBooking(uid, true)),
    openAirExchange: (booking) => dispatch(startAirExchange(booking)),
}))(ModifyModal);

export default Modal;
