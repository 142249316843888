import PropTypes from 'prop-types';
import classnames from 'classnames';
import React from 'react';

export default class Loader extends React.Component {
    static propTypes = {
        top: PropTypes.bool,
        small: PropTypes.bool,
        dark: PropTypes.bool,
        light: PropTypes.bool,
        wrapped: PropTypes.bool,
    };

    static defaultProps = {
        top: false,
        small: true,
        dark: true,
        light: false,
        wrapped: false,
    };

    render() {
        const props = this.props;
        const cls = classnames('loading-spinner', {
            'loading-spinner--top': props.top,
            'loading-spinner--small': props.small,
            'loading-spinner--dark': props.dark,
            'loading-spinner--light': props.light,
        });

        return (<div data-testid="air-loader">
            {props.wrapped ? (
                <div className="loading-spinner__wrapper">
                    <span className={cls} />
                </div>
            ) : (
                <span className={cls} />
            )}
        </div>);
    }
}
