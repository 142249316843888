import {
    createSchema,
    required,
    compactErrors,
    noErrors,
    combineValidators,
} from 'midoffice/newforms/helpers';
import {gettext} from 'airborne/gettext';
import {getStates} from 'airborne/constants';
import {
    ArrayField,
    BooleanField,
    Field,
    CharField,
    EmailField,
    RegexField,
    ChoiceField, PhoneNumberField, NumberField,
} from 'midoffice/newforms/fields-stateless';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import {getCountryNameByCountryCode} from 'airborne/air/checkout/helpers/phoneCodes';
import sortBy from 'lodash/sortBy';
import settings from 'airborne/settings';

const RemarkField = {
    fields: {
        'name': required(CharField),
        'value': required(CharField),
    }
};


const UrlField = {
    ...Field,
    validate(value = '') {
        if (!value) {
            return;
        }
        const regex = /^(http|https)+:\/\/+(www\.)?[-a-zA-Z0-9@:%.\+~#=/]{1,256}(\.|\/)[a-zA-Z0-9()]{1,256}\b([\/-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
        const isCorrect = value.match(regex);
        if (isCorrect) {
            return null;
        }
        return 'Please enter a valid url.';
    }
};


const StrictUrlField = {
    ...Field,
    validate: combineValidators(
        UrlField.validate,
        (value) => !value && Field.emptyMessage
    ),
};
const PoolField = {
    ...Field,
    fields: {
        'vpa_client_id': required(CharField),
        'card_pool_name': required(CharField),
        'bcd_card_pool': BooleanField,
        'id': Field,
    },

    validate({'vpa_client_id': id, 'card_pool_name':name}, uniqList) {
        if (uniqList && uniqList[id] > 1) return {'vpa_client_id': 'This id is not unique. Please enter only unique ids.'};
        return compactErrors({
            'card_pool_name': name ? null : CharField.emptyMessage,
            'vpa_client_id': id ? null : CharField.emptyMessage,
        });
    },
};


export const PaymentInstructionsField = {
    ...RegexField,
    maxLength: 200,
    regex: /^[\w\s\-\,\.\*=:]*$/,
    invalidFormatMessage: gettext('Only uppercase and lowercase latin letters, numbers, spaces, dashes, periods, asterisks and commas are allowed.')
};

function getPhoneChoices() {
    return sortBy(
        Object.entries(settings.PHONE_REGION_CODES || {}).reduce(
            (acc, [countryCode, callingCode]) => {
                return getCountryNameByCountryCode()[countryCode]
                    ? [
                        ...acc,
                        [
                            countryCode,
                            `${getCountryNameByCountryCode()[countryCode]} (+${callingCode})`
                        ]
                    ]
                    : acc;
            },
            []
        ),
        (([, label]) => label)
    );
}

const codeField = required({...ChoiceField, choices: getPhoneChoices()});

const phoneField = required(PhoneNumberField);

const cityField = {
    ...CharField,
    maxLength: 35
};

const BillingPhoneField = required({
    ...Field,
    validate(value) {
        const {country_code: countryCode, number} = value || {};
        const codeErrors = codeField.validate(countryCode);
        const numberErrors = phoneField.validate(number);
        const billingPhoneErrors = {
            'country_code': codeErrors,
            number: numberErrors
        };

        if (!noErrors(billingPhoneErrors)) {
            return billingPhoneErrors;
        }

        return null;
    }
});

const addressField = {
    ...CharField,
    maxLength: 50
};

const postalZipField = {
    ...CharField,
    maxLength: 10
};

const VPASchema = createSchema({
    fields: {
        'vpa_settings:inherits': BooleanField,

        'vpa_settings:general_vpa:is_enabled': required(BooleanField),
        'vpa_settings:general_vpa:vcc_deployment': required(BooleanField),
        'vpa_settings:general_vpa:vpa_provider': required(CharField),
        'vpa_settings:general_vpa:default_card_pool': Field,
        'vpa_settings:general_vpa:card_pools:conferma': required(ArrayField),
        'vpa_settings:general_vpa:card_pools:troovo': required(ArrayField),
        'vpa_settings:general_vpa:card_pools:itelya': required(ArrayField),


        'vpa_settings:hotels:allow_non_vpa_cards': BooleanField,
        'vpa_settings:hotels:card_pools:conferma': required(ArrayField),
        'vpa_settings:hotels:card_pools:troovo': required(ArrayField),
        'vpa_settings:hotels:card_pools:itelya': required(ArrayField),
        'vpa_settings:hotels:payment_option': required(CharField),
        'vpa_settings:hotels:vpa_provider': required(CharField),
        'vpa_settings:hotels:booking_issues_email': required(EmailField),
        'vpa_settings:hotels:extra_validity_days_before_reservation': required({...NumberField, minValue: 1, maxValue: 100}),
        'vpa_settings:hotels:extra_validity_days': required({...NumberField, minValue: 1, maxValue: 100}),
        'vpa_settings:hotels:is_enabled': required(BooleanField),
        'vpa_settings:hotels:vcc_deployment': required(BooleanField),
        'vpa_settings:hotels:default_card_pool': Field,
        'vpa_settings:hotels:billing_phone': BillingPhoneField,
        'vpa_settings:hotels:financial_partner_data': {
            ...ArrayField,
            child: createSchema(RemarkField),
        },
        'vpa_settings:hotels:default_payment_instructions': PaymentInstructionsField,

        'vpa_settings:cars:allow_non_vpa_cards': required(BooleanField),
        'vpa_settings:cars:card_pools:conferma': required(ArrayField),
        'vpa_settings:cars:card_pools:troovo': required(ArrayField),
        'vpa_settings:cars:card_pools:itelya': required(ArrayField),
        'vpa_settings:cars:vpa_provider': required(CharField),
        'vpa_settings:cars:extra_validity_days': required({...NumberField, minValue: 1, maxValue: 100}),
        'vpa_settings:cars:extra_validity_days_before_reservation': required({...NumberField, minValue: 1, maxValue: 100}),
        'vpa_settings:cars:is_enabled': required(BooleanField),

        'vpa_settings:cars:default_card_pool': Field,

        'vpa_settings:cars:financial_partner_data': {
            ...ArrayField,
            child: createSchema(RemarkField),
        },

        'vpa_settings:air:allow_non_vpa_cards': required(BooleanField),
        'vpa_settings:air:card_pools:conferma': required(ArrayField),
        'vpa_settings:air:card_pools:troovo': required(ArrayField),
        'vpa_settings:air:card_pools:itelya': required(ArrayField),
        'vpa_settings:air:vpa_provider': required(CharField),
        'vpa_settings:air:extra_validity_days': required({...NumberField, minValue: 1, maxValue: 100}),
        'vpa_settings:air:is_enabled': BooleanField,
        'vpa_settings:air:vcc_deployment': BooleanField,
        'vpa_settings:air:default_card_pool': Field,

        'vpa_settings:air:financial_partner_data': {
            ...ArrayField,
            child: createSchema(RemarkField),
        },
        'vpa_settings:air:allowed_content': required(ArrayField),

        'vpa_settings:providers:conferma:is_enabled': required(BooleanField),
        'vpa_settings:providers:conferma:api_key': required(CharField),
        'vpa_settings:providers:conferma:booker_id': required(CharField),
        'vpa_settings:providers:conferma:api_url': required(StrictUrlField),
        'vpa_settings:providers:conferma:consumer': required(CharField),
        'vpa_settings:providers:conferma:billing_address:city': required(cityField),
        'vpa_settings:providers:conferma:billing_address:address': required(addressField),
        'vpa_settings:providers:conferma:billing_address:state_code': CharField,
        'vpa_settings:providers:conferma:billing_address:postal_code': required(postalZipField),
        'vpa_settings:providers:conferma:billing_address:country_code': required(CharField),
        'vpa_settings:providers:conferma:card_pools': required({
            ...ArrayField,
            child: PoolField,
            validate: (cardPoolList) => {
                if (isEmpty(cardPoolList)) {
                    return 'Card Pool is required for provider';
                }
                const uniqList = cardPoolList.reduce((acc, cardPool) => {
                    let newVal = acc[cardPool['vpa_client_id']] || 0;
                    return {...acc, [cardPool['vpa_client_id']]: ++newVal};
                }, {});
                const errors = cardPoolList.map((cardpool)=> PoolField.validate(cardpool, uniqList));

                if (!noErrors(errors)) {
                    return errors;
                }

                return null;
            }
        }),
        'vpa_settings:providers:conferma:password': required(CharField),
        'vpa_settings:providers:conferma:agent_id': required(CharField),

        'vpa_settings:providers:troovo:is_enabled': required(BooleanField),
        'vpa_settings:providers:troovo:api_key': required(CharField),
        'vpa_settings:providers:troovo:api_auth_url': required(StrictUrlField),
        'vpa_settings:providers:troovo:api_url': required(StrictUrlField),
        'vpa_settings:providers:troovo:billing_address:city': required(cityField),
        'vpa_settings:providers:troovo:billing_address:address': required(addressField),
        'vpa_settings:providers:troovo:billing_address:state_code': CharField,
        'vpa_settings:providers:troovo:billing_address:postal_code': required(postalZipField),
        'vpa_settings:providers:troovo:billing_address:country_code': required(CharField),
        'vpa_settings:providers:troovo:card_pools': required({
            ...ArrayField,
            child: createSchema(PoolField),
            validate: function (cardPoolList) {
                if (isEmpty(cardPoolList)) {
                    return 'Card Pool is required for provider';
                }
                const uniqList = cardPoolList.reduce((acc, cardPool) => {
                    let newVal = acc[cardPool['vpa_client_id']] || 0;
                    return {...acc, [cardPool['vpa_client_id']]: ++newVal};
                }, {});
                const errors = cardPoolList.map((cardpool)=> PoolField.validate(cardpool, uniqList));

                if (!noErrors(errors)) {
                    return errors;
                }

                return null;
            }
        }),
        'vpa_settings:providers:troovo:password': required(CharField),

        'vpa_settings:providers:itelya:is_enabled': required(BooleanField),
        'vpa_settings:providers:itelya:api_key': required(CharField),
        'vpa_settings:providers:itelya:api_url': required(StrictUrlField),

        'vpa_settings:providers:itelya:bi_api_url': UrlField,
        'vpa_settings:providers:itelya:bi_api_key': CharField,
        'vpa_settings:providers:itelya:bi_password': CharField,
        'vpa_settings:providers:itelya:billing_address:city': required(cityField),
        'vpa_settings:providers:itelya:billing_address:address': required(addressField),
        'vpa_settings:providers:itelya:billing_address:state_code': CharField,
        'vpa_settings:providers:itelya:billing_address:postal_code': required(postalZipField),
        'vpa_settings:providers:itelya:billing_address:country_code': required(CharField),
        'vpa_settings:providers:itelya:card_pools': required({
            ...ArrayField,
            child: createSchema(PoolField),
            validate: function (cardPoolList) {
                if (isEmpty(cardPoolList)) {
                    return 'Card Pool is required for provider';
                }
                const uniqList = cardPoolList.reduce((acc, cardPool) => {
                    let newVal = acc[cardPool['vpa_client_id']] || 0;
                    return {...acc, [cardPool['vpa_client_id']]: ++newVal};
                }, {});
                const errors = cardPoolList.map((cardpool)=> PoolField.validate(cardpool, uniqList));

                if (!noErrors(errors)) {
                    return errors;
                }

                return null;
            }
        }),
        'vpa_settings:providers:itelya:password': required(CharField),
        'vpa_settings:providers:itelya:card_provider': required(CharField),
        'vpa_settings:providers:itelya:hotel_service_codes': ArrayField,
        'vpa_settings:payment_manager:is_enabled': required(BooleanField),
        'vpa_settings:general_vpa:financial_partner_data': {
            ...ArrayField,
            child: createSchema(RemarkField),
        },
    },
    validateState(value) {
        const {
            'vpa_settings:providers:conferma:billing_address:country_code': confermaCountryCode,
            'vpa_settings:providers:itelya:billing_address:country_code': itelyaCountryCode,
            'vpa_settings:providers:troovo:billing_address:country_code': troovoCountryCode,
        } = value;

        const stateCodes = [
            {country: confermaCountryCode, provider: 'conferma'},
            {country: itelyaCountryCode, provider: 'itelya'},
            {country: troovoCountryCode, provider:'troovo'}
        ].filter(state => {
            const states = Object.keys(getStates(state?.country));
            return value[`vpa_settings:providers:${state.provider}:is_enabled`]
                && states.length > 0
                && !states.includes(value[`vpa_settings:providers:${state.provider}:billing_address:state_code`]);
        });

        if (!stateCodes.length) return {};

        const stateCodeErrors = stateCodes.map(state => ({
            [`vpa_settings:providers:${state.provider}:billing_address:state_code`]: CharField.emptyMessage
        }));

        return Object.assign({}, ...stateCodeErrors);
    },
    validate(value) {
        const {
            'vpa_settings:hotels:is_enabled': enabledHotels,
            'vpa_settings:cars:is_enabled': enabledCars,
            'vpa_settings:air:is_enabled': enabledAir,
            'vpa_settings:general_vpa:is_enabled': enabledGeneric,
            'vpa_settings:providers:troovo:is_enabled': enabledtroovo,
            'vpa_settings:providers:conferma:is_enabled': enabledConferma,
            'vpa_settings:providers:itelya:is_enabled': enabledItelya,
        } = value;

        const errors = this.validateFields(value);
        const stateCodeErrors = this.validateState(value);
        const flags = [
            ['hotels', enabledHotels],
            ['cars', enabledCars],
            ['air', enabledAir],
            ['general_vpa', enabledGeneric],
            ['providers:troovo', enabledtroovo],
            ['providers:conferma', enabledConferma],
            ['providers:itelya', enabledItelya],
        ];

        const enableTabs = flags.filter(([, value]) => (value));
        const isNeedToValidate = (key) => {

            return enableTabs.some(([product]) => {
                const provider = value['vpa_settings:' + product + ':vpa_provider'];
                // check product card pools is not empty
                const noValidateCardPools = key.includes('vpa_settings:' + product + ':card_pools') &&
                    !key.includes(provider) && provider;

                return !noValidateCardPools && key.includes(product) && !key.includes('default_card_pool');
            });
        };

        return reduce({...errors, ...stateCodeErrors}, function(result, value, key) {
            if (isNeedToValidate(key)) {
                return {
                    ...result,
                    [key]: value
                };
            }
            return result;
        }, {});
    },

});

export default VPASchema;
