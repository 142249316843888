import ModifyModal from 'midoffice/air-bookings/modify/ModifyModal';
import {connect} from 'react-redux';
import {isAirExchangeActive, isAirExchangeLoading} from 'airborne/air/store/exchange/selectors';
import {startAirExchange} from 'airborne/air/store/exchange/actions';
import {getBookingData} from 'midoffice/store/modules/externalExtra/actions';
import {externalExtraSelectors} from 'midoffice/store/modules/externalExtra';

const Modal = connect((state, {uid}) => {
    return {
        airExchangeLoading: isAirExchangeLoading(state),
        airExchangeActive: isAirExchangeActive(state),
        loading: externalExtraSelectors.isBookingDataLoading(state),
        booking: externalExtraSelectors.getExternalBookingData(state, uid),
        errorMessage: externalExtraSelectors.getDataModalErrorMessage(state),
        isExternalBooking: true,
    };
}, (dispatch, {externalReservationKey, uid}) => ({
    preload: () => dispatch(getBookingData(externalReservationKey, uid)),
    openAirExchange: (booking) => dispatch(startAirExchange(booking, true)),
}))(ModifyModal);

export default Modal;
