import gettext from 'airborne/gettext';

import air from 'airborne/store/modules/search_hotels/actions/air';
import * as actionTypes from '../actionTypes';
import {apiError, userMessages} from "airborne/air/shared/helpers/apiError";
import {showError, showInfo} from "midoffice/data/actions";
import normalize from "airborne/air/shared/helpers/normalize";

function informVoidSuccess() {
    return showInfo(
        gettext('The tickets have been voided.'), 'success'
    );
}

function informVoidError(errors) {
    return showError([
        gettext('The voiding request has failed.'),
        ...userMessages(errors)
    ]);
}

export const requestTicketsVoiding = ({bookingUid, tickets}) => async (dispatch) => {
    dispatch({ type: actionTypes.AIR_TICKETS_VOID_LOADING });

    try {
        const response = await air(
            'POST',
            '/air/documents_manage/',
            {
                booking_uid: bookingUid,
                operation: 'void',
                tickets,
            }
        );

        dispatch({ type: actionTypes.AIR_TICKETS_VOID_LOADED, data: response });
        dispatch(informVoidSuccess());

        const updatedTickets = response.tickets ?? [];
        dispatch({ type: actionTypes.AIR_TICKETS_LOADED, data: normalize(updatedTickets) });
    } catch (error) {
        const errorMessage = apiError(error);
        dispatch({ type: actionTypes.AIR_TICKETS_VOID_FAIL, error: errorMessage });
        dispatch(informVoidError(errorMessage));
    } finally {
        dispatch({ type: actionTypes.AIR_TICKETS_VOID_LOAD_END });
    }
}
